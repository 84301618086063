// Contoh di SuccessAlert.js
import React from 'react';

function SuccessAlert({ successMessage, buttonLabel, onClose }) {
  return (
    <div className="alert-overlay">
      <div className="success-alert p-3 p-md-5">
        <div className='m-0 mx-md-5'>
        <div className="m-5 d-flex flex-column justify-content-center align-items-center">
          <img src="/img/Berhasil.png" alt="Berhasil Submit" style={{height: window.innerWidth >= 768 ? 442 : 200}}/>
          <div className="fs-2 fw-bold my-3">{successMessage}</div>
          <button className="btn-blue-1" onClick={onClose}>
            {buttonLabel}
          </button>
        </div>
        </div>
      </div>
    </div>
  );
}
export default SuccessAlert;
