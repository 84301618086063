import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import React, { useState } from 'react';
import SideBar from './Layout/SideBar';
import Header from './Layout/Header';
import DashboardMahasiswa from './Page/Mahasiswa/DashboardMahasiswa';
import RevisiProposal from './Page/Mahasiswa/Proposal/RevisiProposal';
import Riwayat from './Page/Mahasiswa/Riwayat';
import Kegiatan from './Page/Kegiatan';
import Jadwal from './Page/Jadwal';
import EditProfil from './Page/Mahasiswa/EditProfil';
import DashboardDosen from './Page/Dosen/DashboardDosen';
import DaftarProposalDosen from './Page/Dosen/Proposal/DaftarProposalDosen';
import DaftarLpjDosen from './Page/Dosen/Lpj/DaftarLpjDosen';
import BerkasDisetujui from './Page/Dosen/BerkasDisetujui';
import DetailProposalDosen from './Page/Dosen/Proposal/DetailProposalDosen';
import DashboardAdmin from './Page/Admin/DashboardAdmin';
import Revisi from './Page/Mahasiswa/Revisi';
import UpdateProposal from './Page/Admin/Proposal/UpdateProposal';
import ProposalAdmin from './Page/Admin/Proposal/ProposalAdmin';
import ArsipProposal from './Page/Admin/Proposal/ArsipProposal';
import DeleteProposal from './Page/Admin/Proposal/DeleteProposal';
import TinjauProposalDosen from './Page/Dosen/Proposal/TinjauProposalDosen';
import Proposal from './Page/Mahasiswa/Proposal/Proposal';
import LpjAdmin from './Page/Admin/Lpj/LpjAdmin';
import UpdateLpj from './Page/Admin/Lpj/UpdateLpj';
import ArsipLpj from './Page/Admin/Lpj/ArsipLpj';
import DeleteLpj from './Page/Admin/Lpj/DeleteLpj';
import KelolaAkun from './Page/Admin/Akun/KelolaAkun';
import BuatAkun from './Page/Admin/Akun/BuatAkun';
import UpdateAccounts from './Page/Admin/Akun/UpdateAccounts';
import UpdateAccount from './Page/Admin/Akun/UpdateAccount';
import DeleteAccount from './Page/Admin/Akun/DeleteAccount';
import DetailLpjDosen from './Page/Dosen/Lpj/DetailLpjDosen';
import TinjauLpjDosen from './Page/Dosen/Lpj/TinjauLpjDosen';
import TestingNotif from './Components/TestingNotif';
import DashboardPengunjung from './Page/Pengunjung/DashboardPengunjung';
import DaftarProposalPengunjung from './Page/Pengunjung/Proposal/DaftarProposalPengunjung';
import DaftarLpjPengunjung from './Page/Pengunjung/Lpj/DaftarLpjPengunjung';
import DetailKegiatan from './Page/DetailKegiatan';
import DetailProposalPengunjung from './Page/Pengunjung/Proposal/DetailProposalPengunjung';
import DetailLpjPengunjung from './Page/Pengunjung/Lpj/DetailLpjPengunjung';
import LihatProposal from './Page/Pengunjung/Proposal/LihatProposal';
import LihatLpj from './Page/Pengunjung/Lpj/LihatLpj';
import TinjauProposalMahasiswa from './Page/Mahasiswa/Proposal/TinjauProposalMahasiswa';
import BuatJadwal from './Page/Admin/BuatJadwal';
import Lpj from './Page/Mahasiswa/Lpj/Lpj';
import RevisiLpj from './Page/Mahasiswa/Lpj/RevisiLpj';
import TinjauLpjMahasiswa from './Page/Mahasiswa/Lpj/TinjauLpjlMahasiswa';

function App() {
  const [toggle, setToggle] = useState(true);
  function Toggle() {
    setToggle(!toggle);
  }
  return (
    <Router>
      <div className="d-flex">
        <div className={toggle ? 'sidebar-responsive' : ''}>
          <SideBar Toggle={Toggle} />
        </div>
        <div className='main-content col overflow-auto'>
          <Header Toggle={Toggle} />
          <Routes>
            <>
              <Route path="/mahasiswa/dashboard" element={<DashboardMahasiswa />} />
              <Route path="/mahasiswa/pengajuan/proposal" element={<Proposal />} />
              <Route path="/mahasiswa/pengajuan/proposal/revisi" element={<RevisiProposal />} />
              <Route path="/mahasiswa/pengajuan/proposal/tinjau" element={<TinjauProposalMahasiswa />} />
              <Route path="/mahasiswa/pengajuan/lpj" element={<Lpj />} />
              <Route path="/mahasiswa/pengajuan/lpj/revisi" element={<RevisiLpj />} />
              <Route path="/mahasiswa/pengajuan/lpj/tinjau" element={<TinjauLpjMahasiswa />} />
              <Route path="/mahasiswa/riwayat" element={<Riwayat />} />
              <Route path="/mahasiswa/revisi" element={<Revisi />} />
              <Route path="/mahasiswa/editprofil" element={<EditProfil />} />

            </>
            <>
              <Route path='/dosen/dashboard' element={<DashboardDosen />} />
              <Route path='/dosen/proposal' element={<DaftarProposalDosen />} />
              <Route path='/dosen/proposal/detail/:id' element={<DetailProposalDosen />} />
              <Route path='/dosen/proposal/edit' element={<TinjauProposalDosen />} />
              <Route path='/dosen/lpj' element={<DaftarLpjDosen />} />
              <Route path='/dosen/lpj/detail/:id' element={<DetailLpjDosen />} />
              <Route path='/dosen/lpj/edit' element={<TinjauLpjDosen />} />
              <Route path='/dosen/approved' element={<BerkasDisetujui />} />
            </>
            <>
              <Route path='/admin/dashboard' element={<DashboardAdmin />} />
              <Route path='/admin/proposal' element={<ProposalAdmin />} />
              <Route path='/admin/proposal/update' element={<UpdateProposal />} />
              <Route path='/admin/proposal/arsip' element={<ArsipProposal />} />
              <Route path='/admin/proposal/delete' element={<DeleteProposal />} />
              <Route path='/admin/lpj' element={<LpjAdmin />} />
              <Route path='/admin/lpj/update' element={<UpdateLpj />} />
              <Route path='/admin/lpj/arsip' element={<ArsipLpj />} />
              <Route path='/admin/lpj/delete' element={<DeleteLpj />} />
              <Route path='/admin/account' element={<KelolaAkun />} />
              <Route path='/admin/account/create' element={<BuatAkun />} />
              <Route path='/admin/account/update' element={<UpdateAccounts />} />
              <Route path='/admin/account/update/:id' element={<UpdateAccount />} />
              <Route path='/admin/account/delete' element={<DeleteAccount />} />
              <Route path='/admin/jadwal/add' element={<BuatJadwal />} />
            </>
            <>
              <Route path='/dashboard' element={<DashboardPengunjung />} />
              <Route path='/proposal' element={<DaftarProposalPengunjung />} />
              <Route path='/proposal/detail/:id' element={<DetailProposalPengunjung />} />
              <Route path='/proposal/tinjau/:id' element={<LihatProposal />} />
              <Route path='/lpj' element={<DaftarLpjPengunjung />} />
              <Route path='/lpj/detail/:id' element={<DetailLpjPengunjung />} />
              <Route path='/lpj/tinjau/:id' element={<LihatLpj />} />
            </>
            <>
              <Route path='/testingnotif' element={<TestingNotif />} />
              <Route path='/kegiatan' element={<Kegiatan />} />
              <Route path='/kegiatan/detail/:id' element={<DetailKegiatan />} />
              <Route path='/jadwal' element={<Jadwal />} />
              <Route path='/edit-profil' element={<EditProfil />} />
            </>
            {/* Tambahkan rute lainnya di sini */}
          </Routes>
        </div>
      </div>
    </Router>
  );
}
export default App;