import React from "react";

const PengajuanLpj = ({ nextStep, handleChange, values }) => {

    const handleNext = (e) => {
        e.preventDefault();
        nextStep();
    }

    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Pengajuan LPJ Kegiatan
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Pengajuan LPJ Kegiatan
                </div>
                <form>
                    <div className='row g-2 d-flex flex-row'>
                        <div className='col-12 col-md-6'>
                            <div className="mx-auto my-3 m-md-3 mt-0 col-12 col-md-6">
                                <label htmlFor="nomorKegiatan" className="form-label fs-4 fw-bold">
                                    Nomor Kegiatan
                                </label>
                                <input
                                    type="text"
                                    id="nomorKegiatan"
                                    className="form-control"
                                    placeholder="001/HIMA/PPK/2023"
                                    onChange={handleChange('nomorKegiatan')}
                                    defaultValue={values.nomorKegiatan}
                                />
                            </div>
                            <div className="mx-auto my-3 m-md-3 col-12 col-md-6">
                                <label htmlFor="bidangOrmawa" className="form-label fs-4 fw-bold">
                                    Bidang Ormawa
                                </label>
                                <input
                                    type="text"
                                    id="bidangOrmawa"
                                    className="form-control"
                                    placeholder="HIMASKOM"
                                    onChange={handleChange('bidangOrmawa')}
                                    defaultValue={values.bidangOrmawa}
                                />
                            </div>
                            <div className="mx-auto my-3 m-md-3 col-12 col-md-6">
                                <label htmlFor="alurKegiatan" className="form-label fs-4 fw-bold">
                                    Alur
                                </label>
                                <select
                                    id="alurKegiatan"
                                    className="form-select"
                                    onChange={handleChange('alurKegiatan')}
                                    defaultValue={values.alurKegiatan}
                                >
                                    <option defaultValue=''>Pilih Alur</option>
                                    <option value="1">Kegiatan Himaskom</option>
                                    <option value="2">Kegiatan Al-Muharrik</option>
                                    <option value="3">CERC</option>
                                    <option value="4">UKM</option>
                                </select>
                            </div>
                            <div className="mx-auto my-3 m-md-3">
                                <label htmlFor="ruangLingkupKegiatan" className="form-label fs-4 fw-bold">
                                    Ruang Lingkup Kegiatan
                                </label>
                                <input
                                    type="text"
                                    id="ruangLingkupKegiatan"
                                    className="form-control"
                                    placeholder="Masukkan Ruang Lingkup Kegiatan"
                                    onChange={handleChange('ruangLingkupkegiatan')}
                                    defaultValue={values.ruangLingkupKegiatan}
                                />
                            </div>
                        </div>
                        <div className='col-12 col-md-6 '>
                            <img className="d-none d-md-block" src="/img/Pengajuan.png" alt="Pengajuan" />
                        </div>
                        <div className='d-flex justify-content-center'>
                            <button
                                className="my-5 bg-blue-1 text-white border-0 rounded-3 py-2"
                                style={{width: '100px'}}
                                onClick={handleNext}
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default PengajuanLpj;