import React from 'react';
import TabelDokumen from '../../Components/TabelDokumen';

function Revisi() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/mahasiswa/revisi" className='text-decoration-none text-black'>Revisi</a>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Revisi Dokumen
                </div>
                <TabelDokumen role="mahasiswa" pageData="revisi" searchNFilter="yes"></TabelDokumen>
            </div>
        </div>
    );
}

export default Revisi;
