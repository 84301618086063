import React from 'react';
import TinjauDokumen from '../../../Components/TinjauDokumen';

function LihatProposal() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/proposal" className='text-decoration-none text-black'>Proposal Kegiatan</a>
                    <i className='bi bi-chevron-right mx-2'></i>
                    <span className='text-decoration-none text-black'>Tinjau Proposal Kegiatan</span>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Tinjau Dokumen Proposal Kegiatan Mahasiswa
                </div>
                <TinjauDokumen dokumen="Proposal" role="pengunjung" />
            </div>
        </div>
    );
}

export default LihatProposal;