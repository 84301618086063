import React, { useState } from "react";

function Header({ Toggle }) {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <nav
      className="d-flex align-items-center bg-blue-1"
      style={{ height: "88px" }}
    >
      <div className="container-fluid">
        <div className="d-flex justify-content-between mx-1">
          <div className="d-flex align-items-center col-6 col-md-9">
            <button
              className="bg-transparent border-0 text-decoration-none text-white d-block d-lg-none"
              onClick={Toggle}
            >
              <i className="bi bi-list"></i>
            </button>
            <span className="text-decoration-none text-white ms-3 ms-lg-0 fw-bold fs-5 d-md-block d-none">
              Sistem Informasi Pengelolaan Dokumen Pengajuan Kegiatan Mahasiswa
            </span>
            <span className="ms-2 text-decoration-none text-white fw-bold fs-6 d-md-none d-block">
              SIPDPKM
            </span>
          </div>
          <div className="col-6 col-md-3">
            <div className="d-flex flex-row mb-2 mb-lg-0 justify-content-end align-items-center ms-auto ms-md-0 me-md-3">
              <div className="me-0 me-md-3">
                <div className="dropdown">
                  <div
                    onClick={handleDropdownToggle}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src="https://dummyimage.com/300"
                      alt="FotoProfil"
                      height={52}
                      width={52}
                      style={{ borderRadius: "50%" }}
                    />
                  </div>
                  <ul
                    className={`dropdown-menu mt-4 bg-blue-1 rounded-3 border border-1 ${
                      isDropdownOpen ? "show" : ""
                    }`}
                    style={{ right: window.innerWidth >= 768 ? "auto" : 0, width: "200px" }}
                  >
                    <li className="fs-7 text-white fw-bold text-center px-2 py-1 d-block d-md-none">Nama lengkap</li>
                    <li className="fs-7 text-white text-center px-2 py-1 d-block d-md-none">Mahasiswa</li>
                    <hr className="text-white my-1 d-block d-md-none"/>
                    <li>
                      <a
                        href="/edit-profil"
                        className="px-3 py-2 dropdown-item fs-7 text-white rounded-1"
                      >
                        <img src="/img/account.svg" className="me-3" alt="" height={24} width={24} />
                        Edit Profil
                      </a>
                    </li>
                    <li>
                      <a
                        href="/signout"
                        className="px-3 py-2 dropdown-item fs-7 text-white rounded-1"
                      >
                        <img src="/img/signout.svg" className="me-3" alt="" height={24} width={24} />
                        Sign Out
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="fs-6 text-white text-center d-flex flex-column ms-1 align-items-center d-none d-md-block">
                <div className="fw-bold">Nama Lengkap</div>
                <div className="">Mahasiswa</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Header;
