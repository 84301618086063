import React, { useState } from 'react';
import ConfirmationAlert from '../../Components/ConfirmationAlert';
import SuccessAlert from '../../Components/SuccesAlert';
import { useNavigate } from 'react-router-dom';

const BuatJadwal = () => {
    
    const [activityData, setActivityData] = useState({
        name: '',
        place: '',
        organization: '',
        time: '',
        start: '',
        end: '',
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setActivityData({
            ...activityData,
            [name]: value,
        });
    };

    const handleSave = (e) => {
        console.log([activityData]);
        setActivityData({
          name: "",
          place: "",
          organization: "",
          time: "",
          start: "",
          end: "",
        });
    };

    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const navigate = useNavigate();

    const handleConfirm = () => {
        setShowConfirmationAlert(true);
    };
    
    const confirmData = () => {
        setShowConfirmationAlert(false);
        setShowSuccessAlert(true);
        handleSave();
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
        navigate('/admin/dashboard');
    };

    return (
      <div className="p-3">
        <div className="container-fluid">
          <div
            className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
            style={{ backgroundColor: "#f0f0f0" }}
          >
            Tambah Kegiatan Mahasiswa
          </div>
          <div className="my-3 fs-2 fw-bold text-black">
            Buat Jadwal Kegiatan Mahasiswa
          </div>
          <div className="mx-2 my-4 col">
            <div className="">
              <div className="pb-4">
                <form>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Nama Kegiatan
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="name"
                        value={activityData.name}
                        onChange={handleInputChange}
                        placeholder="Masukkan Nama Kegiatan"
                      />
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Tempat Kegiatan Dilaksanakan
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="place"
                        value={activityData.place}
                        onChange={handleInputChange}
                        placeholder="Masukkan Tempat Kegiatan Dilaksanakan"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">Bidang</label>
                      <select
                        name="organization"
                        value={activityData.organization}
                        onChange={handleInputChange}
                        className="form-select"
                      >
                        <option value="" disabled>
                          Pilih Bidang
                        </option>
                        <option value="Himaskom">HIMASKOM</option>
                        <option value="Al-Muharrik">Al-Muharrik</option>
                        <option value="CERC">CERC</option>
                        <option value="UKM">UKM</option>
                      </select>
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Jam Pelaksanaan
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="time"
                        value={activityData.time}
                        onChange={handleInputChange}
                        placeholder="Jam Mulai - Jam Selesai"
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Tanggal Kegiatan Mulai
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="start"
                        value={activityData.start}
                        onChange={handleInputChange}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Tanggal Kegiatan Selesai
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="end"
                        value={activityData.end}
                        onChange={handleInputChange}
                        placeholder="DD/MM/YYYY"
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-5 d-flex justify-content-center">
                <button
                  className="btn btn-blue-1 me-2"
                  style={{ width: "100px" }}
                >
                  Cancel
                </button>
                <button
                  className="btn btn-blue-1"
                  style={{ width: "100px" }}
                  onClick={handleConfirm}
                >
                  Confirm
                </button>
              </div>
            </div>
            {showConfirmationAlert && (
              <ConfirmationAlert
                showAlert={showConfirmationAlert}
                confirmationMessage="Anda Yakin untuk Mengunggah Status Dokumen Ke Sistem?"
                buttonLabel1="Submit Document"
                buttonLabel2="Back to Check Page"
                onConfirm={confirmData}
                onClose={closeConfirmationAlert}
              />
            )}
            {showSuccessAlert && (
              <SuccessAlert
                showAlert={showSuccessAlert}
                successMessage="Submit Dokumen Berhasil!"
                buttonLabel="Back to Dashboard"
                onClose={closeSuccessAlert}
              />
            )}
          </div>
        </div>
      </div>
    );
};

export default BuatJadwal;
