import React, { useState } from 'react';
import ConfirmationAlert from '../../../Components/ConfirmationAlert';
import SuccessAlert from '../../../Components/SuccesAlert';
import { useNavigate } from 'react-router-dom';

const UpdateAccount = () => {
    const [userData, setuserData] = useState({
        firstName: 'John',
        lastName: 'Doe',
        email: 'johndoe@example.com',
        phoneNumber: '08123456789',
        role: 'Mahasiswa',
        position: 'Sekretaris',
        organization: 'HIMASKOM',
    });
    
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setuserData({
            ...userData,
            [name]: value,
        });
    };

    const navigate = useNavigate();

    const handleCancel = () => {
        navigate('/admin/account/update')
    }

    const handleConfirm = () => {
        setShowConfirmationAlert(true);
    };

    const confirmData = () => {
        setShowConfirmationAlert(false);
        console.log('Data Profil Tersimpan:', userData);
        setShowSuccessAlert(true);
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
        navigate('/admin/account/update');
        window.location.reload();
    }

    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Kelola Akun Pengguna
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Perbarui Akun Pengguna Aplikasi
                </div>
                <div className="mx-2 my-4 col">
                    <div className="mb-5">
                        <div className="d-flex align-items-center">
                            <img
                                src="https://via.placeholder.com/150"
                                alt="Foto Profil"
                                className="rounded-circle"
                                height={130}
                                width={130}
                            />
                            <div className='ms-5 d-flex flex-column'>
                                <span className='fs-4 fw-bold'>{userData.firstName + " " + userData.lastName}</span>
                                <span className='fw-semibold'>{userData.role}</span>
                                <span className='fw-semibold'>{userData.position + " " + userData.organization}</span>
                            </div>
                        </div>
                    </div>
                    <div className="">
                        <div className='pb-4'>
                            <form>
                                <div className="row mb-3">
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Nama Depan</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            value={userData.firstName}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nama Depan Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>Nama Belakang</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            value={userData.lastName}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nama Belakang Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Alamat Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Alamat Email Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>No. Handphone</label>
                                        <input
                                            type="tel"
                                            className="form-control"
                                            name="phoneNumber"
                                            value={userData.phoneNumber}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nomor Handphone Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Jabatan</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="position"
                                            value={userData.position}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Jabatan Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>Organisasi</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="organization"
                                            value={userData.organization}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Organisasi Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col-4 me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Jenis Akun Pengguna</label>
                                        <select
                                            name="role"
                                            value={userData.role}
                                            onChange={handleInputChange}
                                            className="form-select"
                                        >
                                            <option value="mahasiswa">Mahasiswa</option>
                                            <option value="dosen">Dosen Kemahasiswaan</option>
                                            <option value="kadep">Kepala Departemen</option>
                                            <option value="admin">Admin</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="my-5 d-flex justify-content-end">
                            <button className="btn btn-blue-1 me-2" style={{ width: "100px" }} onClick={handleCancel}>Cancel</button>
                            <button className="btn btn-blue-1" style={{ width: "100px" }} onClick={handleConfirm}>
                                Save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            {showConfirmationAlert && (
                <ConfirmationAlert
                    showAlert={showConfirmationAlert}
                    confirmationMessage="Anda Yakin untuk Memperbarui Akun?"
                    buttonLabel1="Update Account"
                    buttonLabel2="Back to Previous Page"
                    onConfirm={confirmData}
                    onClose={closeConfirmationAlert}
                />
            )}
            {showSuccessAlert && (
                <SuccessAlert
                    showAlert={showSuccessAlert}
                    successMessage="Akun Berhasil Diperbarui!"
                    buttonLabel="Back to List"
                    onClose={closeSuccessAlert}
                />
            )}
        </div>
    );
};

export default UpdateAccount;
