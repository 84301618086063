import React from "react";
import { useNavigate } from "react-router-dom";

const DetailKegiatan = ({ title }) => {
  const navigate = useNavigate();
  const onClose = () => {
    navigate(-1);
  };
  const viewDocument = () => {
    navigate("/proposal/tinjau");
  };

  // const detailProposal = (id) => {
  //   if (dokumen && dokumen.id) {
  //     let navigateUrl = "";
  //     if (role === "mahasiswa") {
  //       navigateUrl = `/mahasiswa/pengajuan/proposal/tinjau/${dokumen.id.toLowerCase()}/view/${id}`;
  //     } else if (role === "dosen") {
  //       navigateUrl = `/dosen/proposal/tinjau${dokumen.id.toLowerCase()}/view/${id}`;
  //     } else if (role === "kadep") {
  //       navigateUrl = `/kadep/proposal/tinjau${dokumen.id.toLowerCase()}/view/${id}`;
  //     } else if (role === "admin") {
  //       navigateUrl = `/admin/proposal/tinjau${dokumen.id.toLowerCase()}/view/${id}`;
  //     } else if (role === "pengunjung") {
  //       navigateUrl = `/proposal/tinjau/${dokumen.id.toLowerCase()}/view/${id}`;
  //     }

  //     navigate(navigateUrl);
  //   } else {
  //     console.error("Type is undefined or the document doesn't exist.");
  //   }
  // }

  const detailProposal = () => {
    navigate('/proposal/detail')
  }

  return (
    <div className="p-3">
      <div className="container-fluid">
        <div
          className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <div className="text-decoration-none text-black">Detail Kegiatan</div>
        </div>
        <div className="my-3 fs-2 fw-bold text-black">
          Detail Kegiatan Mahasiswa
        </div>
        <div className="row g-1 g-md-2 mb-5">
          <div className="col-12 col-xl-6">
            <div className="mb-3 fs-2 fw-bold text-black">{title}</div>
            <div className="px-2 px-md-3">
              <div className="mb-5">
                <div className="fs-4 fw-bold mb-3">Detail Kegiatan</div>
                <div className="row mx-1 mx-lg-3 g-1">
                  <div className="col-6 col-md-4">Nama Kegiatan</div>
                  <div className="col-6 col-md-8">{title}</div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Ormawa</div>
                  <div className="col-6 col-md-8"></div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Tanggal Mulai</div>
                  <div className="col-6 col-md-8">22/07/2023</div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Tanggal Selesai</div>
                  <div className="col-6 col-md-8">23/07/2023</div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Waktu</div>
                  <div className="col-6 col-md-8"></div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Tempat</div>
                  <div className="col-6 col-md-8"></div>
                  <div className="w-100 py-1"></div>
                  <div className="col-6 col-md-4">Tujuan Kegiatan</div>
                  <div className="col-6 col-md-8"></div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-xl-6">
            <center>
              <div className="d-none d-md-block pb-4">
                <img src="/img/form.png" alt="" />
              </div>
            </center>
          </div>
        </div>
        <div className="row g-1 g-md-2 mt-5 pt-3">
          <div className="col-12 col-xl-6">
            <div className="d-flex flex-column mx-3 col-6">
              <div className="d-flex flex-row align-items-center">
                <div className="col">
                  <img src="/img/qr.png" alt="" height={110} width={110} />
                </div>
                <div className="d-flex flex-column gap-1 text-gray-4">
                  <span className="badge status-disetujui w-100">Sudah Disetujui</span>
                  <span>001/HIMA/PPK/2023</span>
                  <span>HIMASKOM</span>
                </div>
              </div>
            </div>
            {/* <div className="mt-5 d-flex flex-column align-items-center">
            <img src="/img/alert.png" alt="" height={64} width={64} />
            <div>
              Dokumen tidak bisa diunduh karena belum mendapatkan persetujuan
            </div>
          </div> */}
          </div>
          <div className="col-12 col-xl-6">
            <center>
              <div className="d-flex flex-column w-50 gap-3">
                <button className="btn-blue-1">View Proposal</button>
                <button className="btn-blue-1">View LPJ</button>
              </div>
            </center>
          </div>
        </div>
        <div className="my-5 mx-3 gap-3 d-flex justify-content-center">
          <button className="btn-blue-1" onClick={onClose}>
            Close
          </button>
        </div>
      </div>
    </div>
  );
};

export default DetailKegiatan;
