import React from 'react';
import TabelDokumen from '../../Components/TabelDokumen';

function BerkasDisetujui() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/dosen/approved" className='text-decoration-none text-black'>Berkas yang Sudah Disetujui</a>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Daftar Berkas yang Sudah Disetujui
                </div>
            </div>
            <div className='m-2'>
                <TabelDokumen
                role="dosen"
                pageData="disetujui"
                searchNFilter="yes"
                edit="yes"
                detail="yes" />
            </div>
        </div>
    );
}

export default BerkasDisetujui;