import React from 'react';
import TabelDokumen from '../../../Components/TabelDokumen';

function DaftarLpjDosen() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/dosen/lpj" className='text-decoration-none text-black'>Daftar Pengajuan Laporan Pertanggungjawaban</a>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Daftar Laporan Pertanggungjawaban Kegiatan Mahasiswa
                </div>
            </div>
            <div className='m-2'>
                <TabelDokumen
                    role="dosen"
                    pageData="lpj"
                    searchNFilter="yes"
                    edit="yes"
                    detail="yes"
                />
            </div>
        </div>
    );
}

export default DaftarLpjDosen;