import React, { useEffect, useState } from 'react';
import DetailDokumen from '../../../Components/DetailDokumen';
import { useParams } from 'react-router-dom';
import dataDokumen from '../../../dokumen.json';

function DetailLpjPengunjung() {
    const { id } = useParams();
    
    const idNumber = parseInt(id);
    const [dokumen, setDokumen] = useState(null);

    useEffect(() => {
        const dokumenById = dataDokumen.find((item) => item.id === idNumber);
        if (dokumenById) {
            setDokumen(dokumenById);
        } else {
            console.error("Dokumen dengan ID yang diberikan tidak ditemukan.");
        }
    }, [idNumber]);

    return (
        <div className='p-2 p-md-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <span className='text-decoration-none text-black'>Detail Kegiatan</span>
                </div>
            </div>
            <DetailDokumen dokumen={dokumen} role="pengunjung" />
        </div>
    );
}

export default DetailLpjPengunjung;
