import React from "react";
import Kalender from "../Components/Kalender";
import { useNavigate } from "react-router-dom";

function Jadwal() {
  const role = "";
  const navigate = useNavigate();

  return (
    <div className="p-3">
      <div className="container-fluid">
        <div
          className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <span className="text-decoration-none text-black">Jadwal</span>
        </div>
        <div className="my-3 d-flex justify-content-between">
          <div className="fs-2 fw-bold text-black">Jadwal Kegiatan</div>
          {role === "admin" && (
            <button
              className="my-1 btn-blue-1"
              onClick={() => navigate("/admin/jadwal/add")}
            >
              Add Activity
            </button>
          )}
        </div>
        <div className="p-3">
          <Kalender />
        </div>
      </div>
    </div>
  );
}
export default Jadwal;
