import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dataDokumen from "../dokumen.json";

const TabelDokumen = ({
  role,
  pageData,
  searchNFilter,
  edit,
  detail,
  hapus,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [dataOrder, setDataOrder] = useState("Terbaru");

  const [filteredDokumen, setFilteredDokumen] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  useEffect(() => {
    let showedData = [];
    if (pageData === "semua") {
      showedData = dataDokumen;
    } else if (pageData === "revisi") {
      showedData = dataDokumen.filter((item) => item.status === "Revisi");
    } else if (pageData === "disetujui") {
      showedData = dataDokumen.filter((item) => item.status === "Disetujui");
    } else if (pageData === "proposal") {
      showedData = dataDokumen.filter((item) => item.type === "Proposal");
    } else if (pageData === "lpj") {
      showedData = dataDokumen.filter((item) => item.type === "LPJ");
    }

    const dataFilter = (data) => {
      if (dataOrder === "Terbaru") {
        return data.sort((a, b) => new Date(b.date) - new Date(a.date));
      } else if (dataOrder === "Disetujui") {
        return data.filter((item) => item.status === "Disetujui");
      } else if (dataOrder === "Ditolak") {
        return data.filter((item) => item.status === "Ditolak");
      } else if (dataOrder === "Revisi") {
        return data.filter((item) => item.status === "Revisi");
      } else if (dataOrder === "Diproses") {
        return data.filter((item) => item.status === "Diproses");
      } else if (dataOrder === "Proposal") {
        return data.filter((item) => item.type === "Proposal");
      } else if (dataOrder === "LPJ") {
        return data.filter((item) => item.type === "LPJ");
      } else if (dataOrder === "HIMASKOM") {
        return data.filter((item) => item.organization === "HIMASKOM");
      } else if (dataOrder === "Al-Muharrik") {
        return data.filter((item) => item.organization === "Al-Muharrik");
      } else if (dataOrder === "CERC") {
        return data.filter((item) => item.organization === "CERC");
      } else {
        return data;
      }
    };

    const filteredData = showedData.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLocaleLowerCase())
    );

    const dataResult = dataFilter(filteredData);

    setFilteredDokumen(dataResult);
  }, [searchTerm, dataOrder, pageData]);

  const navigate = useNavigate();

  const handleEdit = (id) => {
    const dokumen = dataDokumen.find((item) => item.id === id);
    if (dokumen && dokumen.type) {
      let navigateUrl = "";
      if (role === "mahasiswa") {
        navigateUrl = `/mahasiswa/${dokumen.type.toLowerCase()}/edit/${id}`;
      } else if (role === "dosen") {
        navigateUrl = `/dosen/${dokumen.type.toLowerCase()}/edit/${id}`;
      } else if (role === "kadep") {
        navigateUrl = `/kadep/${dokumen.type.toLowerCase()}/edit/${id}`;
      } else if (role === "admin") {
        navigateUrl = `/admin/${dokumen.type.toLowerCase()}/edit/${id}`;
      }

      navigate(navigateUrl);
    } else {
      console.error("Type is undefined or the document doesn't exist.");
    }
  };

  const handleDetail = (id) => {
    const dokumen = dataDokumen.find((item) => item.id === id);
    if (dokumen && dokumen.type) {
      let navigateUrl = "";
      if (role === "mahasiswa") {
        navigateUrl = `/mahasiswa/${dokumen.type.toLowerCase()}/detail/${id}`;
      } else if (role === "dosen") {
        navigateUrl = `/dosen/${dokumen.type.toLowerCase()}/detail/${id}`;
      } else if (role === "kadep") {
        navigateUrl = `/kadep/${dokumen.type.toLowerCase()}/detail/${id}`;
      } else if (role === "admin") {
        navigateUrl = `/admin/${dokumen.type.toLowerCase()}/detail/${id}`;
      } else if (role === "pengunjung") {
        navigateUrl = `/${dokumen.type.toLowerCase()}/detail/${id}`;
      }

      navigate(navigateUrl);
    } else {
      console.error("Type is undefined or the document doesn't exist.");
    }
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleDataOrderChange = (e) => {
    setDataOrder(e.target.value);
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredDokumen.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  return (
    <div className="mx-0 m-md-3">
      {searchNFilter === "yes" && (
        <div className="d-flex flex-column flex-md-row justify-content-between mb-4 gap-3">
          <div className="col-12 col-md-3">
            <input
              type="text"
              placeholder="Cari dokumen..."
              value={searchTerm}
              onChange={handleSearchChange}
              className="form-control me-2"
            />
          </div>
          <div className="col-12 col-md-3 d-flex align-items-center">
            <span className="fw-semibold me-3">Filter</span>
            <select
              value={dataOrder}
              onChange={handleDataOrderChange}
              className="form-select"
            >
              <option value="Terbaru">Terbaru</option>
              {pageData === "semua" && (
                <>
                  <option value="Disetujui">Disetujui</option>
                  <option value="Ditolak">Ditolak</option>
                  <option value="Revisi">Revisi</option>
                  <option value="Diproses">Diproses</option>
                </>
              )}
              {pageData === "revisi" && (
                <>
                  <option value="Proposal">Proposal</option>
                  <option value="LPJ">LPJ</option>
                </>
              )}
              {(pageData === "proposal" ||
                pageData === "lpj" ||
                pageData === "disetujui") && (
                <>
                  <option value="HIMASKOM">HIMASKOM</option>
                  <option value="Al-Muharrik">Al-Muharrik</option>
                  <option value="CERC">CERC</option>
                </>
              )}
            </select>
          </div>
        </div>
      )}

      <div className="dokumen table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="px-3">No.</th>
              <th>Title</th>
              <th>Organization</th>
              <th>Date</th>
              <th>Type</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id}>
                <td className="px-4">{index + 1 + indexOfFirstItem}</td>
                <td>{item.title}</td>
                <td>{item.organization}</td>
                <td>{item.date}</td>
                <td>{item.type}</td>
                <td>
                  <span className={`badge status-${item.status.toLowerCase()}`}>
                    {item.status}
                  </span>
                </td>
                <td>
                  {role === "mahasiswa" && (item.status === "Diproses" || item.status === "Revisi" || item.status === "Ditolak") && (
                      <button
                        onClick={() => handleEdit(item.id)}
                        className="btn rounded-circle"
                      >
                        <img src="/img/edit.svg" alt="Edit" />
                      </button>
                    )}
                  {role === "mahasiswa" && item.status === "Disetujui" && (
                    <button
                      onClick={() => handleDetail(item.id)}
                      className="btn rounded-circle"
                    >
                      <img src="/img/detail.svg" alt="Detail" />
                    </button>
                  )}
                  {(role === "dosen" || role === "admin") && edit === "yes" && (
                    <>
                      <button
                        onClick={() => handleEdit(item.id)}
                        className="btn rounded-circle"
                      >
                        <img src="/img/edit.svg" alt="Edit" />
                      </button>
                    </>
                  )}
                  {(role === "dosen" || role === "admin") &&
                    detail === "yes" && (
                      <>
                        <button
                          onClick={() => handleDetail(item.id)}
                          className="btn rounded-circle ms-2"
                        >
                          <img src="/img/detail.svg" alt="Detail" />
                        </button>
                      </>
                    )}
                  {role === "admin" && hapus === "yes" && (
                    <>
                      <button
                        onClick={() => handleDetail(item.id)}
                        className="btn rounded-circle ms-2"
                      >
                        <img src="/img/delete.svg" alt="Delete" />
                      </button>
                    </>
                  )}
                  {role === "pengunjung" && detail === "yes" && (
                    <>
                      <button
                        onClick={() => handleDetail(item.id)}
                        className="btn rounded-circle ms-2"
                      >
                        <img src="/img/detail.svg" alt="Detail" />
                      </button>
                    </>
                  )}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <div className="my-5">
        <nav>
          <ul className="pagination d-flex justify-content-between">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link bg-blue-1 text-white rounded-3"
                style={{ width: "100px" }}
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            <div className="text-center">
              Halaman {currentPage} dari{" "}
              {Math.ceil(filteredDokumen.length / itemsPerPage)}
            </div>
            <li
              className={`page-item ${
                currentPage === Math.ceil(filteredDokumen.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link bg-blue-1 text-white rounded-3"
                style={{ width: "100px" }}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
};

export default TabelDokumen;
