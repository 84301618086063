import React from "react";

function NotificationsAlert({ onClose, onConfirm }) {
  return (
    <div className="alert-overlay">
      <div className="confirmation-alert py-5 px-3 p-md-5">
        <div className="p-0 m-0 p-md-5 m-md-5">
          <img src="/img/alert.png" alt="" />
          <h4 className="my-4" style={{ color: "#004fac" }}>
            Anda Memiliki Beberapa Berkas Yang Belum Ditinjau
          </h4>
          <div className="mx-3 d-flex flex-column gap-3 align-items-center">
            <button
              className="btn btn-blue-1"
              style={{ width: "300px" }}
              onClick={onClose}
            >
              Back To Dashboard
            </button>
            <button
              className="btn btn-blue-1"
              style={{ width: "300px" }}
              onClick={onConfirm}
            >
              Check Notification
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationsAlert;
