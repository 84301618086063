import React, { useState } from 'react';
import ConfirmationAlert from '../../Components/ConfirmationAlert';
import SuccessAlert from '../../Components/SuccesAlert';
import { useNavigate } from 'react-router-dom';

const EditProfil = () => {
    
    const [profileData, setProfileData] = useState({
        firstName: 'John',
        lastName: 'Doe',
        email: 'johndoe@example.com',
        phoneNumber: '08123456789',
        role: 'Mahasiswa',
        position: 'Sekretaris',
        organization: 'HIMASKOM',
    });

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setProfileData({
            ...profileData,
            [name]: value,
        });
    };

    const navigate = useNavigate();

    const [showConfirmationAlert,setShowConfirmationAlert] = useState(false);
    const [showSuccessAlert,setShowSuccessAlert] = useState(false);

    const handleCancel = () => {
        navigate(-1)
    };

    const handleConfirm = () => {
        setShowConfirmationAlert(true);
    };

    const confirmData = () => {
        setShowConfirmationAlert(false);
        setShowSuccessAlert(true);
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
        navigate(-1);
        window.location.reload();
    };

    return (
      <div className="p-3">
        <div className="container-fluid">
          <div
            className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <a
              href="/mahasiswa/editprofil"
              className="text-decoration-none text-black"
            >
              Pengaturan Akun
            </a>
          </div>
          <div className="my-3 fs-2 fw-bold text-black">Profil Saya</div>
          <div className="mx-2 my-4 col">
            <div className="mb-5">
              <div className="d-flex align-items-center">
                <img
                  src="https://via.placeholder.com/150"
                  alt="Foto Profil"
                  className="rounded-circle"
                  height={130}
                  width={130}
                />
                <div className="ms-5 d-flex flex-column">
                  <span className="fs-4 fw-bold">
                    {profileData.firstName + " " + profileData.lastName}
                  </span>
                  <span className="fw-semibold">{profileData.role}</span>
                  <span className="fw-semibold">
                    {profileData.position + " " + profileData.organization}
                  </span>
                </div>
              </div>
            </div>
            <div className="">
              <div className="pb-4">
                <form>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Nama Depan
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="firstName"
                        value={profileData.firstName}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Nama Belakang
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="lastName"
                        value={profileData.lastName}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Alamat Email
                      </label>
                      <input
                        type="email"
                        className="form-control"
                        name="email"
                        value={profileData.email}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        No. Handphone
                      </label>
                      <input
                        type="tel"
                        className="form-control"
                        name="phoneNumber"
                        value={profileData.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="row mb-3">
                    <div className="col me-5">
                      <label className="fs-4 fw-semibold mb-1">Jabatan</label>
                      <input
                        type="text"
                        className="form-control"
                        name="position"
                        value={profileData.position}
                        onChange={handleInputChange}
                      />
                    </div>
                    <div className="col ms-5">
                      <label className="fs-4 fw-semibold mb-1">
                        Bidang Ormawa
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        name="organization"
                        value={profileData.organization}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                </form>
              </div>
              <div className="my-5 d-flex justify-content-between">
                <button className="btn btn-blue-1" style={{ width: "100px" }}>
                  Logout
                </button>
                <div>
                  <button
                    className="btn btn-blue-1 me-2"
                    style={{ width: "100px" }}
                    onClick={handleCancel}
                  >
                    Cancel
                  </button>
                  <button
                    className="btn btn-blue-1"
                    style={{ width: "100px" }}
                    onClick={handleConfirm}
                  >
                    Save
                  </button>
                </div>
              </div>
              {showConfirmationAlert && (
                <ConfirmationAlert
                  showAlert={showConfirmationAlert}
                  confirmationMessage="Anda Yakin untuk Memperbarui Profil?"
                  buttonLabel1="Update Profile"
                  buttonLabel2="Back to Previous Page"
                  onConfirm={confirmData}
                  onClose={closeConfirmationAlert}
                />
              )}
              {showSuccessAlert && (
                <SuccessAlert
                  showAlert={showSuccessAlert}
                  successMessage="Profil Berhasil Diperbarui!"
                  buttonLabel="Back to Previous Page"
                  onClose={closeSuccessAlert}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
};

export default EditProfil;
