import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import dataKegiatan from "../kegiatan.json";

function TabelKegiatan() {
  const [searchTerm, setSearchTerm] = useState("");
  const [dataOrder, setDataOrder] = useState("Terbaru");

  const [filteredKegiatan, setFilteredKegiatan] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  
  const getStatus = (start, end) => {
    const currentDate = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (currentDate < startDate) {
      return "coming";
    } else if (currentDate >= startDate && currentDate <= endDate) {
      return "ongoing";
    } else {
      return "finished";
    }
  };

  useEffect(() => {
    const dataFilter = (data) => {
      if (dataOrder === "Terbaru") {
        return data.sort((a, b) => new Date(b.start) - new Date(a.start));
      } else if (dataOrder === "Ongoing") {
        return data.filter((item) => item.status === "ongoing");
      } else if (dataOrder === "Coming") {
        return data.filter((item) => item.status === "coming");
      } else if (dataOrder === "Finished") {
        return data.filter((item) => item.status === "finished");
      }
    };
      

    const searchResult = dataKegiatan.filter((item) =>
      item.title.toLowerCase().includes(searchTerm.toLowerCase())
    );

    const activityStatus = searchResult.map((item) => ({
      ...item,
      status: getStatus(item.start, item.end),
    }));

    const sortResult = dataFilter(activityStatus);

    setFilteredKegiatan(sortResult);
  }, [searchTerm, dataOrder]);

  const navigate = useNavigate();

  const handleDetail = (id) => {
    navigate(`/kegiatan/detail/${id}`);
  };
  const handleArsip = (id) => {
    navigate(`/mahasiswa/kegiatan/arsip/${id}`);
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value);
  };

  const handleSortOrderChange = (e) => {
    setDataOrder(e.target.value);
  };

  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const getStatusText = (status) => {
    switch (status) {
      case "ongoing":
        return "On Going";
      case "coming":
        return "Coming";
      case "finished":
        return "Finished";
      default:
        return status;
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = filteredKegiatan.slice(
    indexOfFirstItem,
    indexOfLastItem
  );

  return (
    <div className="m-3">
      <div className="d-flex flex-column flex-md-row justify-content-between mb-3 gap-2">
        <div className="col-6 col-md-3">
          <input
            type="text"
            placeholder="Cari kegiatan..."
            value={searchTerm}
            onChange={handleSearchChange}
            className="form-control me-2"
          />
        </div>
        <div className="col-6 col-md-3 d-flex align-items-center">
          <span className="fw-semibold me-3">Filter</span>
          <select
            value={dataOrder}
            onChange={handleSortOrderChange}
            className="form-select"
          >
            <option value="Terbaru">Terbaru</option>
            <option value="Ongoing">Sedang Berlangsung</option>
            <option value="Coming">Akan Datang</option>
            <option value="Finished">Selesai</option>
          </select>
        </div>
        </div>
      <div className="kegiatan table-responsive">
        <table className="table table-striped">
          <thead>
            <tr>
              <th className="ps-3">No.</th>
              <th>Detail</th>
              <th>Title</th>
              <th>Organization</th>
              <th>Start Date</th>
              <th>End Date</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {currentItems.map((item, index) => (
              <tr key={item.id}>
                <td className="ps-4">{index + 1 + indexOfFirstItem}</td>
                <td>
                  <button
                    onClick={() => handleDetail(item.id)}
                    className="btn rounded-circle"
                  >
                    <img src="/img/detail.svg" alt="" />
                  </button>
                </td>
                <td>{item.title}</td>
                <td>{item.organization}</td>
                <td>{item.start}</td>
                <td>{item.end}</td>
                <td>
                  <span className={`badge status-${item.status.toLowerCase()}`}>
                    {getStatusText(item.status)}
                  </span>
                </td>
                <td>
                  <button
                    onClick={() => handleArsip(item.id)}
                    className="btn rounded-circle"
                  >
                    <img src="/img/download.svg" alt="" />
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <div className="my-5">
        <nav>
          <ul className="pagination d-flex justify-content-between">
            <li className={`page-item ${currentPage === 1 ? "disabled" : ""}`}>
              <button
                className="page-link bg-blue-1 text-white rounded-3"
                style={{ width: "100px" }}
                onClick={() => paginate(currentPage - 1)}
              >
                Previous
              </button>
            </li>
            <div>
              Halaman {currentPage} dari{" "}
              {Math.ceil(filteredKegiatan.length / itemsPerPage)}
            </div>
            <li
              className={`page-item ${
                currentPage ===
                Math.ceil(filteredKegiatan.length / itemsPerPage)
                  ? "disabled"
                  : ""
              }`}
            >
              <button
                className="page-link bg-blue-1 text-white rounded-3"
                style={{ width: "100px" }}
                onClick={() => paginate(currentPage + 1)}
              >
                Next
              </button>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  );
}

export default TabelKegiatan;
