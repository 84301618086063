import React from 'react';

function KelolaAkun() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Kelola Akun Pengguna
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Kelola Akun Pengguna
                </div>
                <div className='my-5 my-md-0 row g-2 d-flex'>
                    <div className='col-12 col-md-6 d-flex flex-column gap-3 align-items-center justify-content-center'>
                        <a href='/admin/account/create' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Create New User Account</a>
                        <a href='/admin/account/update' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Update User Account</a>
                        <a href='/admin/account/delete' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Delete User Account</a>
                    </div>
                    <div className='col-12 col-md-6 d-none d-md-block'>
                        <img src="/img/Pengajuan.png" alt="Pengajuan"  style={{width: window.innerWidth >= 992 ? 500 : 350}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default KelolaAkun;