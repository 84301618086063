import React, { useState } from "react";

const SideBarMahasiswa = () => {
  const [isOpen, setOpen] = useState(false);
  const submenuClasses = isOpen
    ? "nav collapse ms-4 mt-2 flex-column fs-6 collapsing"
    : "nav collapse ms-4 mt-2 flex-column fs-6";
  return (
    <div>
      <ul className="nav nav-pills flex-column" id="menu">
        <li className="nav-item ps-2 py-2 my-2">
          <a
            href="/mahasiswa/dashboard"
            className="text-decoration-none text-white px-1 align-items-center d-flex"
          >
            <img src="/img/home.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3">Dashboard</span>
          </a>
        </li>
        <li
          className="nav-item ps-2 py-1"
          onClick={(e) => {
            setOpen((prev) => !prev);
          }}
        >
          <button
            className="bg-transparent border-0 nav-link text-white p-1 align-items-center d-flex"
            data-bs-toggle="collapse"
            data-bs-target="#submenuPengajuan"
            aria-expanded="false"
            aria-controls="submenuPengajuan"
          >
            <img src="/img/document.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3 me-5">Pengajuan</span>
            <i
              className={
                isOpen
                  ? "bi bi-caret-up-fill ms-4 fs-6"
                  : "bi bi-caret-down-fill ms-4 fs-6"
              }
            ></i>
          </button>
        </li>

        <ul
          className={submenuClasses}
          data-bs-parent="#menu"
          id="submenuPengajuan"
        >
          <li className="nav-item flex-row d-flex my-1 align-items-center">
            <a
              className="nav-link ps-1 text-white w-100"
              href="/mahasiswa/pengajuan/proposal"
              aria-current="page"
            >
              <img src="/img/document.svg" height={24} width={24} alt="" />
              <span className="ps-2">Proposal</span>
            </a>
          </li>
          <li className="nav-item flex-row d-flex my-1 align-items-center">
            <a
              className="nav-link ps-1 text-white w-100"
              href="/mahasiswa/pengajuan/lpj"
              aria-current="page"
            >
              <img src="/img/document.svg" height={24} width={24} alt="" />
              <span className="ps-2">LPJ</span>
            </a>
          </li>
        </ul>

        <li className="nav-item ps-2 py-2 my-2">
          <a
            href="/mahasiswa/riwayat"
            className="text-decoration-none text-white px-1 align-items-center d-flex"
          >
            <img src="/img/history.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3">Riwayat</span>
          </a>
        </li>
        <li className="nav-item ps-2 py-2 my-2">
          <a
            href="/mahasiswa/revisi"
            className="text-decoration-none text-white px-1 align-items-center d-flex"
          >
            <img src="/img/revised.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3">Revisi</span>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default SideBarMahasiswa;
