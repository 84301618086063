import React, { useEffect, useState } from 'react';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import timeGridPlugin from '@fullcalendar/timegrid';
import moment from 'moment';
import dataKegiatan from '../kegiatan.json';

const Kalender = () => {
  const [events, setEvents] = useState([]);

  // Fungsi untuk menghitung status kegiatan
  const getStatus = (start, end) => {
    const currentDate = new Date();
    const startDate = new Date(start);
    const endDate = new Date(end);

    if (currentDate < startDate) {
      return 'coming'; // Kegiatan yang akan datang
    } else if (currentDate >= startDate && currentDate <= endDate) {
      return 'ongoing'; // Kegiatan yang sedang berlangsung
    } else {
      return 'finished'; // Kegiatan yang sudah selesai
    }
  };

  // Mengatur data kegiatan dengan properti status
  useEffect(() => {
    const formattedEvents = dataKegiatan.map((event) => {
      const [startTime, endTime] = event.time.split('-');
      const start = moment(`${event.start} ${startTime}`, 'YYYY/MM/DD HH:mm').format();
      const end = moment(`${event.end} ${endTime}`, 'YYYY/MM/DD HH:mm').format();
      const status = getStatus(event.start, event.end);

      return {
        ...event,
        title: event.title,
        start,
        end,
        status, // Menambahkan properti status
      };
    });

    setEvents(formattedEvents);
  }, []);

  // Custom styling untuk event
  const eventContent = (eventInfo) => {
    const isLongTitle = eventInfo.event.title.length > 20; // Misalnya, jika judul lebih dari 20 karakter
    const eventClass = isLongTitle ? 'long-title' : ''; // Tambahkan class 'long-title' jika judul panjang
    return (
      <div className={`d-flex align-items-start flex-column custom-event ${eventInfo.event.extendedProps.status.toLowerCase()} ${eventClass}`}>
        <strong style={{ height: "30px" }}>{eventInfo.event.title}</strong>
        <br />
        <span className='flex-row my-1'>
          <img className='me-2' src="/img/clock.svg" alt="" />
          {moment(eventInfo.event.start).format('HH:mm')} - {moment(eventInfo.event.end).format('HH:mm')}
        </span>
      </div>
    );
  };

  // Gaya CSS khusus untuk mengatur lebar kalender
  const calendarStyle = {
    maxWidth: '100%', // Menggunakan 100% lebar maksimal
    overflowX: 'auto', // Menambahkan scroll horizontal jika diperlukan
  };

  return (
    <div style={calendarStyle}>
      <FullCalendar
        plugins={[dayGridPlugin, interactionPlugin, timeGridPlugin]}
        initialView="dayGridMonth"
        events={events}
        eventContent={eventContent} // Menggunakan custom styling
        headerToolbar={{
          start: "prev",
          center: "title",
          end: "next",
        }}
      />
      <style>
        {`
          .fc-toolbar-title { font-size: 24px !important; } // Mengatur ukuran teks di headerToolbar
        `}
      </style>
    </div>
  );
};

export default Kalender;
