import React from "react";
import SideBarMahasiswa from "./SideBarMahasiswa";
import SideBarDosen from "./SideBarDosen";
import SideBarAdmin from "./SideBarAdmin";
import SideBarPengunjung from "./SideBarPengunjung";

function SideBar({ Toggle }) {
  const role = "mahasiswa";
  return (
    <div
      className="sidebar d-flex justify-content-between flex-column text-white p-2 vh-100"
      style={{ backgroundColor: "#001349" }}
    >
      <div className="d-flex flex-row justify-content-center position-relative mx-4">
        <span className="px-2 py-3">
          <img src="/img/Logo.PNG" alt="Logo" />
        </span>
        <span>
          <button
            className="bg-transparent border-0 text-decoration-none position-absolute start-100 text-white d-block d-lg-none"
            onClick={Toggle}
          >
            <i className="bi bi-x-lg"></i>
          </button>
        </span>
      </div>
      <hr className="mx-2 border border-1 border-white opacity-100" />
      {role === "mahasiswa" && <SideBarMahasiswa />}
      {role === "dosen" && <SideBarDosen />}
      {role === "admin" && <SideBarAdmin />}
      {role === "pengunjung" && <SideBarPengunjung />}
      <ul className="nav nav-pills flex-column" id="menu">
        <li className="nav-item px-2 py-2 my-2">
          <a
            href="/kegiatan"
            className="text-decoration-none text-white px-1 align-items-center d-flex"
          >
            <img src="/img/activity.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3">Kegiatan</span>
          </a>
        </li>
        <li className="nav-item px-2 py-2 my-2">
          <a
            href="/jadwal"
            className="text-decoration-none text-white px-1 align-items-center d-flex"
          >
            <img src="/img/schedule.svg" height={24} width={24} alt="" />
            <span className="fs-6 ms-3">Jadwal</span>
          </a>
        </li>
      </ul>
      <div className="my-5 mx-1">
        <p className="text-center">
          <strong>Contact Us</strong>
        </p>
        <p>
          <strong>Alamat:</strong> Jl. Prof. H. Soedarto, SH Tembalang,
          Semarang, Indonesia 1269
        </p>
        <p>
          <strong>Email:</strong> tekkom@undip.ac.id
        </p>
        <p>
          <strong>No. Telpon:</strong> (024) 76480709
        </p>
        <hr className="mx-2 border border-1 border-white opacity-100" />
        <p className="text-center fs-6 flex-column d-flex">
          <span>© 2022 Kelompok 3</span>
          <span>All rights reserved</span>
        </p>
      </div>
    </div>
  );
}

export default SideBar;
