import React from "react";
import TabelDokumen from "../../Components/TabelDokumen";

function DashboardPengunjung() {
  return (
    <div className="p-3">
      <div
        className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
        style={{ backgroundColor: "#f0f0f0" }}
      >
        <span>Selamat Datang </span>
        <span></span>
      </div>
      <div className="container-fluid">
        <div className="my-5 mx-2">
          <TabelDokumen
            role="pengunjung"
            pageData="semua"
            detail="yes"
          ></TabelDokumen>
        </div>
      </div>
    </div>
  );
}

export default DashboardPengunjung;
