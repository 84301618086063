import React, { useState } from 'react';

const BuatAkun = () => {
    const [userData, setUserData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        phoneNumber: '',
        role: '',
        position: '',
        organization: '',
    })

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setUserData({
            ...userData,
            [name]: value,
        });
    };

    const handleSave = (e) => {
        e.preventDefault();
        console.log([userData]);
        setUserData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            role: '',
            position: '',
            organization: '',
        })
    };

    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Buat Akun Baru
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Buat Akun Pengguna Baru
                </div>
                <div className="mx-2 my-4 col">
                    <div className="mb-5">
                        {/* Foto profil dan informasi */}
                        <div className="d-flex align-items-center">
                            <img
                                src="/img/user.png"
                                alt="Foto Profil"
                                className="rounded-circle"
                                height={130}
                                width={130}
                            />
                        </div>
                    </div>
                    <div className="">
                        {/* Form Edit Profil */}
                        <div className='pb-4'>
                            <form>
                                <div className="row mb-3">
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Nama Depan</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="firstName"
                                            value={userData.firstName}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nama Depan Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>Nama Belakang</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="lastName"
                                            value={userData.lastName}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nama Belakang Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Alamat Email</label>
                                        <input
                                            type="email"
                                            className="form-control"
                                            name="email"
                                            value={userData.email}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Alamat Email Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>No. Handphone</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="phoneNumber"
                                            value={userData.phoneNumber}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Nomor Handphone Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Jabatan</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="position"
                                            value={userData.position}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Jabatan Pengguna'
                                        />
                                    </div>
                                    <div className="col ms-5">
                                        <label className='fs-4 fw-semibold mb-1'>Organisasi</label>
                                        <input
                                            type="text"
                                            className="form-control"
                                            name="organization"
                                            value={userData.organization}
                                            onChange={handleInputChange}
                                            placeholder='Masukkan Organisasi Pengguna'
                                        />
                                    </div>
                                </div>
                                <div className='row mb-3'>
                                    <div className="col-4 me-5">
                                        <label className='fs-4 fw-semibold mb-1'>Jenis Akun Pengguna</label>
                                        <select
                                            name="role"
                                            value={userData.role}
                                            onChange={handleInputChange}
                                            className="form-select"
                                        >
                                            <option value='' disabled>Pilih Role Pengguna</option>
                                            <option value="mahasiswa">Mahasiswa</option>
                                            <option value="dosen">Dosen Kemahasiswaan</option>
                                            <option value="kadep">Kepala Departemen</option>
                                            <option value="admin">Admin</option>
                                        </select>
                                    </div>
                                </div>
                            </form>
                        </div>
                        {/* Tombol Aksi */}
                        <div className="my-5 d-flex justify-content-end">
                            <button className="btn btn-blue-1 me-2" style={{ width: "100px" }}>Cancel</button>
                            <button className="btn btn-blue-1" style={{ width: "100px" }} onClick={handleSave}>Save</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default BuatAkun;
