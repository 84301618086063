import React, { useState } from "react";
import ConfirmationAlert from "./ConfirmationAlert";
import SuccessAlert from "./SuccesAlert";
import { useNavigate } from "react-router-dom";
import PDFEditor from "./PDFEditor";

function EditDokumen({ role, dokumen }) {
  const [komentar, setKomentar] = useState("");
  const [status, setStatus] = useState("");
  const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);

  const [disetujuiDisabled, setDisetujuiDisabled] = useState(false);
  const [revisiDisabled, setRevisiDisabled] = useState(false);
  const [ditolakDisabled, setDitolakDisabled] = useState(false);

  const handleStatusClick = (newStatus) => {
    if (newStatus === status) {
      setStatus("");
      setDisetujuiDisabled(false);
      setRevisiDisabled(false);
      setDitolakDisabled(false);
    } else {
      setStatus(newStatus);
      setDisetujuiDisabled(newStatus === "Disetujui" ? false : true);
      setRevisiDisabled(newStatus === "Revisi" ? false : true);
      setDitolakDisabled(newStatus === "Ditolak" ? false : true);
    }
  };

  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };
  const handleConfirm = () => {
    if (status === "") {
      alert("Anda harus memilih status dokumen terlebih dahulu");
      return;
    }

    if (status === "Revisi" && komentar === "") {
      alert("Anda harus mengisi komentar jika memilih status Revisi");
      return;
    }
    setShowConfirmationAlert(true);
  };

  const confirmData = () => {
    console.log("Data komentar:", komentar);
    console.log("Data status:", status);
    setShowConfirmationAlert(false);
    setShowSuccessAlert(true);
  };

  const closeConfirmationAlert = () => {
    setShowConfirmationAlert(false);
  };

  const closeSuccessAlert = () => {
    setShowSuccessAlert(false);
    navigate("/admin/dashboard");
  };

  const [pdfUrl, setPdfUrl] = useState("/proposal1.pdf");

  return (
    <div className="mx-1">
      <div
        className="d-flex flex-column flex-md-row justify-content-center mt-4 gap-4"
      >
        <div
          className="border border-1 border-black rounded-3 overflow-hidden position-relative"
          style={{ width: "800px", maxWidth: "100%" }}
        >
          <div
            className="bg-gray-3 d-flex justify-content-center align-items-center fs-4 fw-bold text-center lh-1 border-bottom border-black"
            style={{ height: "50px" }}
          >
            Dokumen {dokumen} Kegiatan Mahasiswa
          </div>
          <div
            style={{
              height: "500px",
              overflowY: "scroll",
              backgroundColor: "#606060",
              padding: "5px",
            }}
          >
            <PDFEditor fileUrl={pdfUrl}/>
          </div>
          <div
            className="bg-gray-3 border-top border-black"
            style={{ height: "50px" }}
          >
            <div className="ms-0 ms-md-4 me-5 me-md-0 p-2 d-flex gap-2" style={{fontSize: window.innerWidth >= 768 ? "16px" : "12px"}}>
              <button
                className={`bg-green-1 border-0 rounded-3 text-white ${
                  status === "Disetujui" ? "selected" : ""
                }`}
                style={{ width: "80px" }}
                onClick={() => handleStatusClick("Disetujui")}
                disabled={disetujuiDisabled}
              >
                Disetujui
              </button>
              <button
                className={`bg-yellow-1 border-0 rounded-3 text-white ${
                  status === "Revisi" ? "selected" : ""
                }`}
                style={{ width: "80px" }}
                onClick={() => handleStatusClick("Revisi")}
                disabled={revisiDisabled}
              >
                Revisi
              </button>
              <button
                className={`bg-red-1 border-0 rounded-3 text-white ${
                  status === "Ditolak" ? "selected" : ""
                }`}
                style={{ width: "80px" }}
                onClick={() => handleStatusClick("Ditolak")}
                disabled={ditolakDisabled}
              >
                Ditolak
              </button>
            </div>
          </div>
        </div>
        <div
          className="border border-1 border-black rounded-3 overflow-hidden"
          style={{ width: "300px", maxWidth: "100%" }}
        >
          <div
            className="bg-gray-3 d-flex justify-content-center align-items-center fs-4 text-center lh-1 fw-bold border-bottom border-black"
            style={{ height: "50px" }}
          >
            Kolom Komentar
          </div>
          {role === "mahasiswa" && <div style={{ height: "400px" }}></div>}
          {role === "dosen" && (
            <div style={{ height: 500 }}>
              <textarea
                style={{ width: "100%", height: "100%" }}
                name="komentar"
                id="komentar"
                cols="30"
                rows="20"
                value={komentar}
                onChange={(e) => setKomentar(e.target.value)}
              ></textarea>
            </div>
          )}
          {role === "mahasiswa" && (
            <div
              className="border-top border-black d-flex"
              style={{ height: "150px" }}
            >
              <img src="/img/qr.png" alt="" height={110} width={110} />
              <div className="my-3">
                <div className="badge rounded-pill fs-6 bg-green-1">
                  Disetujui
                </div>
                <div className="">001/HIMA/PPK/2023</div>
                <div className="">HIMASKOM</div>
              </div>
            </div>
          )}
        </div>
      </div>
      {role === "mahasiswa" && (
        <div className="mt-4 d-flex justify-content-end gap-3">
          <button className="btn-blue-1">Download Dokumen</button>
          <button className="btn-blue-1">Keluar</button>
        </div>
      )}
      {role === "dosen" && (
        <div className="mt-4 d-flex justify-content-end gap-3">
          <button
            className="btn-blue-1"
            style={{ width: "120px" }}
            onClick={handleClose}
          >
            Close
          </button>
          <button
            className="btn-blue-1"
            style={{ width: "120px" }}
            onClick={handleConfirm}
          >
            Confirm
          </button>
        </div>
      )}

      {showConfirmationAlert && (
        <ConfirmationAlert
          showAlert={showConfirmationAlert}
          confirmationMessage="Anda Yakin untuk Mengunggah Status Dokumen Ke Sistem?"
          buttonLabel1="Submit Document"
          buttonLabel2="Back to Check Page"
          onConfirm={confirmData}
          onClose={closeConfirmationAlert}
        />
      )}
      {showSuccessAlert && (
        <SuccessAlert
          showAlert={showSuccessAlert}
          successMessage="Submit Dokumen Berhasil!"
          buttonLabel="Back to Dashboard"
          onClose={closeSuccessAlert}
        />
      )}
    </div>
  );
}

export default EditDokumen;
