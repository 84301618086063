import React from "react";
import TabelDokumen from "../../../Components/TabelDokumen";

function DaftarProposalDosen() {
  return (
    <div className="p-3">
      <div className="container-fluid">
        <div
          className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
          style={{ backgroundColor: "#f0f0f0" }}
        >
          <div>
            Daftar Pengajuan Proposal
          </div>
        </div>
        <div className="my-3 fs-2 fw-bold text-black">
          Daftar Proposal Kegiatan Mahasiswa
        </div>
      </div>
      <div className="m-2">
        <TabelDokumen
          role="dosen"
          pageData="proposal"
          searchNFilter="yes"
          edit="yes"
          detail="yes"
        />
      </div>
    </div>
  );
}

export default DaftarProposalDosen;
