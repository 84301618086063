import React from 'react';
import TabelDokumen from '../../Components/TabelDokumen';

function DashboardAdmin() {
    return (
        <>
            <div className="p-3">
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <span>Selamat Datang, </span>
                    <span>Nancy Wheller</span>
                </div>
                <div className="container-fluid">
                    <div className="row display-flex g-4">
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <button className="d-flex p-3 bg-blue-1 border rounded-5 align-items-center text-white box">
                                <img src="/img/approved.svg" alt="" height={48} width={48} />
                                <div className="d-flex flex-column mx-auto text-center fw-bold align-items-center">
                                    <span>Arsip Dokumen Proposal</span>
                                    <h2>10</h2>
                                </div>
                            </button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <button className="d-flex p-3 bg-blue-1 border rounded-5 align-items-center text-white box">
                                <img src="/img/approved.svg" alt="" height={48} width={48} />
                                <div className="d-flex flex-column mx-auto text-center fw-bold align-items-center">
                                    <span>Arsip Dokumen LPJ</span>
                                    <h2>10</h2>
                                </div>
                            </button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <button className="d-flex p-3 bg-blue-1 border rounded-5 align-items-center text-white box">
                                <img src="/img/activity.svg" alt="" height={48} width={48} />
                                <div className="d-flex flex-column mx-auto text-center fw-bold align-items-center">
                                    <span>Kegiatan Mahasiswa</span>
                                    <h2>10</h2>
                                </div>
                            </button>
                        </div>
                        <div className="col-12 col-sm-6 col-md-4 col-lg-3">
                            <button className="d-flex p-3 bg-blue-1 border rounded-5 align-items-center text-white box">
                                <img src="/img/account.svg" alt="" height={48} width={48} />
                                <div className="d-flex flex-column mx-auto text-center fw-bold align-items-center">
                                    <span>Akun Aktif</span>
                                    <h2>10</h2>
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="my-5 mx-0 mx-md-2">
                        <TabelDokumen
                            role="dosen"
                            pageData="semua"
                            edit="yes"
                            detail="yes" />
                    </div>
                </div>

            </div>
        </>
    );
}

export default DashboardAdmin;