import React, { useState } from "react";
import NotificationsAlert from "./NotificationsAlert";
import { useNavigate } from "react-router-dom";
import NotificationLists from "./NotificationLists";

function TestingNotif() {
  const [showNotificationsAlert, setShowNotificationsAlert] = useState(false);
  const [showNotificationLists, setShowNotificationLists] = useState(false);

  const handleShowNotificationsAlert = () => {
    setShowNotificationsAlert(true);
  };

  const navigate = useNavigate();

  const closeNotificationsAlert = () => {
    setShowNotificationsAlert(false);
    navigate("/mahasiswa/dashboard");
  };

  const showLists = () => {
    setShowNotificationsAlert(false);
    setShowNotificationLists(true);
  };

  const closeNotificationLists = () => {
    setShowNotificationLists(false);
    navigate("/mahasiswa/dashboard");
  };

  const confirmData = () => {
    setShowNotificationLists(false);
    navigate("/mahasiswa/pengajuan/proposal/tinjau");
  };

  return (
    <div className="p-5">
      <button onClick={handleShowNotificationsAlert}>
        Tampilkan Notifikasi
      </button>
      {showNotificationsAlert && (
        <NotificationsAlert
          showAlert={showNotificationsAlert}
          onClose={closeNotificationsAlert}
          onConfirm={showLists}
        />
      )}
      {showNotificationLists && (
        <NotificationLists
          showAlert={showNotificationLists}
          onClose={closeNotificationLists}
          onConfirm={confirmData}
        />
      )}
    </div>
  );
}

export default TestingNotif;
