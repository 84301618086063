import React, { useEffect, useState } from "react";
import PDFViewer from "../Components/PDFViewer";
import { useNavigate, useParams } from "react-router-dom";
import dataDokumen from '../dokumen.json';

function TinjauDokumen() {

  const navigate = useNavigate();
  const { id } = useParams();
    
  const idNumber = parseInt(id);
  const [dokumen, setDokumen] = useState(null);

  useEffect(() => {
      const dokumenById = dataDokumen.find((item) => item.id === idNumber);
      if (dokumenById) {
          setDokumen(dokumenById);
      } else {
          console.error("Dokumen dengan ID yang diberikan tidak ditemukan.");
      }
  }, [idNumber]);

    const handleDownload = () => {
        navigate('');
    }

    const handleClose = () => {
        navigate(-1);
    }
    
    return (
      <div className="mx-3">
        <div
          className="d-flex justify-content-center mt-4 gap-4"
          style={{ height: "600px" }}
        >
          <div
            className="border border-1 border-black rounded-3 overflow-hidden"
            style={{ width: "800px" }}
          >
            <div
              className="bg-gray-3 d-flex justify-content-center align-items-center fs-4 fw-bold border-bottom border-black"
              style={{ height: "50px" }}
            >
              Dokumen {dokumen ? dokumen.type : ""} Kegiatan Mahasiswa
            </div>
            <div
              style={{
                height: "500px",
                overflowY: "scroll",
                backgroundColor: "#606060",
                padding: "5px",
              }}
            >
              <PDFViewer />
            </div>
            <div
              className="bg-gray-3 border-top border-black"
              style={{ height: "50px" }}
            ></div>
          </div>
          <div
            className="border border-1 border-black rounded-3 overflow-hidden"
            style={{ width: "300px" }}
          >
            <div
              className="bg-gray-3 d-flex justify-content-center align-items-center fs-4 fw-bold border-bottom border-black"
              style={{ height: "50px" }}
            >
              Kolom Komentar
            </div>
            <div style={{ height: "400px" }}></div>
            <div
              className="border-top border-black d-flex"
              style={{ height: "150px" }}
            >
              <img src="/img/qr.png" alt="" height={100} width={100} />
              <div className="my-3">
                <div className="badge rounded-pill fs-6 bg-green-1">
                  Disetujui
                </div>
                <div className="">001/HIMA/PPK/2023</div>
                <div className="">{dokumen ? dokumen.organization : ""}</div>
              </div>
            </div>
          </div>
        </div>
        <div className="my-4 d-flex justify-content-end gap-3">
          <button className="btn-blue-1" onClick={handleDownload}>Download Document</button>
          <button className="btn-blue-1" onClick={handleClose}>Close</button>
        </div>
      </div>
    );
}

export default TinjauDokumen;