import React from 'react';
import TabelDokumen from '../../Components/TabelDokumen';

function Riwayat() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/mahasiswa/riwayat" className='text-decoration-none text-black'>Riwayat</a>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Riwayat Dokumen
                </div>
                <TabelDokumen role="mahasiswa" pageData="semua" searchNFilter="yes"></TabelDokumen>
            </div>
        </div>
    );
};

export default Riwayat;
