// ConfirmationAlert.js
import React from 'react';

function ConfirmationAlert({ confirmationMessage, buttonLabel1, buttonLabel2, onConfirm, onClose }) {
  return (
    <div className="alert-overlay">
      <div className="confirmation-alert py-5 px-3 p-md-5">
        <div className='p-0 m-0 p-md-5 m-md-5'>
        <img src="/img/alert.png" alt="" />
        <h4 className='my-4' style={{color: '#004fac'}}>{confirmationMessage}</h4>
        <div className='mx-3 d-flex flex-column gap-3 align-items-center'>
          <button className="btn btn-blue-1" style={{ width: '300px' }} onClick={onConfirm}>
            {buttonLabel1}
          </button>
          <button className="btn btn-blue-1" style={{ width: '300px' }} onClick={onClose}>
            {buttonLabel2}
          </button>
        </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmationAlert;
