import React from "react";
import TabelDokumen from "./TabelDokumen";

function NotificationLists({ onClose, onConfirm }) {
  return (
    <div className="alert-overlay">
      <div className="notification-alert p-3">
        <div className="">
          <div onClick={onConfirm} style={{cursor: "pointer"}}>
            <TabelDokumen role="mahasiswa" pageData="revisi" />
          </div>
          <div className="mx-3 d-flex flex-column gap-3 align-items-center">
            <button
              className="btn btn-blue-1"
              style={{ width: "300px" }}
              onClick={onClose}
            >
              Back To Dashboard
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default NotificationLists;
