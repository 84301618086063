import React, { useState, useEffect, useRef } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import { PDFDocument } from "pdf-lib";
import QRCode from "qrcode";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const PDFEditor = ({ fileUrl }) => {
  const [numPages, setNumPages] = useState(null);
  const [filePath, setFilePath] = useState(null);
  const [selectedPage, setSelectedPage] = useState(null);
  const [images, setImages] = useState([]);
  const [draggingImage, setDraggingImage] = useState(null);
  const [mousePosition, setMousePosition] = useState({ x: 0, y: 0 });
  const pagesRefs = useRef([]);

  useEffect(() => {
    setFilePath(fileUrl);
  }, [fileUrl]);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);
  };

  const generateQRCode = async (data) => {
    return QRCode.toDataURL(data, { errorCorrectionLevel: "H" });
  };

  const addQRCode = async () => {
    if (!filePath) return;
    const pdfDoc = await readExistingPDF(filePath);
    const qrCodeDataURL = await generateQRCode("Data QR Code");
    await insertQRCode(pdfDoc, qrCodeDataURL);
    const newFilePath = await savePDFDocument(pdfDoc, filePath);
    setFilePath(newFilePath);
  };

  const readExistingPDF = async (filePath) => {
    const pdfBytes = await fetch(filePath).then((res) => res.arrayBuffer());
    return PDFDocument.load(pdfBytes);
  };

  const insertQRCode = async (pdfDoc, qrCodeDataURL) => {
    const pages = pdfDoc.getPages();
    for (let i = 0; i < pages.length; i++) {
      const page = pages[i];
      const { width, height } = page.getSize();
      const qrCodeImage = await pdfDoc.embedPng(qrCodeDataURL);
      page.drawImage(qrCodeImage, {
        x: 530,
        y: height - 70,
        width: 50,
        height: 50,
      });
    }
  };

  const handlePageClick = (pageNumber) => {
    setSelectedPage(pageNumber);
    console.log(pageNumber);
  };

  const handleImageDragStart = (e, image) => {
    setDraggingImage(image);
    const offsetX = e.clientX - image.position.x;
    const offsetY = e.clientY - image.position.y;
    setMousePosition({ x: offsetX, y: offsetY });
  };

  const handleImageDrag = (e) => {
    if (draggingImage) {
      const { clientX, clientY } = e;
      const offsetX = clientX - mousePosition.x;
      const offsetY = clientY - mousePosition.y;
      const updateImages = images.map((img) =>
        img === draggingImage
          ? { ...img, position: { x: offsetX, y: offsetY } }
          : img
      );
      setImages(updateImages);
    }
  };

  const handleImageDragEnd = () => {
    setDraggingImage(null);
  };

  const handleCanvasDrop = (e) => {
    e.preventDefault();
  };

  const handleImageSelection = async (e) => {
    e.preventDefault();
    const imageFile = e.target.files[0];
    if (!imageFile) return;
    const imageUrl = URL.createObjectURL(imageFile);
    const newImage = {
      imageUrl,
      position: { x: 300, y: 500 },
      page: selectedPage,
    };
    setImages([...images, newImage]);
  };

  useEffect(() => {
    if (draggingImage) {
      document.addEventListener("mousemove", handleImageDrag);
      document.addEventListener("mouseup", handleImageDragEnd);
    } else {
      document.removeEventListener("mousemove", handleImageDrag);
      document.removeEventListener("mouseup", handleImageDragEnd);
    }
    return () => {
      document.removeEventListener("mousemove", handleImageDrag);
      document.removeEventListener("mouseup", handleImageDragEnd);
    };
  }, [draggingImage]);

  const embedImagesInPDF = async (pdfDoc) => {
    const pages = pdfDoc.getPages();
    for (const image of images) {
      if (image.page === selectedPage) {
        try {
          const page = pages[selectedPage - 1];
          const { width, height } = page.getSize();
          const pngData = await fetch(image.imageUrl).then((res) =>
            res.arrayBuffer()
          );
          const pngImage = await pdfDoc.embedPng(pngData);
          const aspectRatio = pngImage.width / pngImage.height;
          const calculatedWidth = 70 * aspectRatio;
          page.drawImage(pngImage, {
            x: image.position.x - (0.25 * calculatedWidth),
            y: height - image.position.y - 70,
            width: calculatedWidth,
            height: 70,
          });
        } catch (error) {
          console.error("Error embedding image:", error);
        }
      }
    }
  };

  const savePDFDocument = async (pdfDoc, filePath) => {
    const pdfBytes = await pdfDoc.save();
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    return url;
  };

  const handleDownloadPDF = async () => {
    if (!filePath) return;
    const pdfDoc = await readExistingPDF(filePath);
    await embedImagesInPDF(pdfDoc);
    const newFilePath = await savePDFDocument(pdfDoc, filePath);
    const pdfBytes = await fetch(newFilePath).then((res) => res.arrayBuffer());
    const blob = new Blob([pdfBytes], { type: "application/pdf" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "edited-document.pdf";
    a.style.display = "none";
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  };

  return (
    <div className="container">
      <center>
        <button
          className="sign-button"
          onClick={() => {
            addQRCode();
            document.querySelector(".input-field").click();
          }}
        >
          <img src="/img/sign.svg" height={24} width={24} alt="" />
        </button>
        <input
          type="file"
          accept="image/png"
          className="input-field"
          hidden
          onChange={(e) => handleImageSelection(e)}
        />
        <div>
          <Document file={filePath} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from(new Array(numPages), (el, index) => (
              <div
                className={`page-thumbnail ${
                  selectedPage === index + 1 ? "selected" : ""
                }`}
                onClick={() => handlePageClick(index + 1)}
                key={`page_${index + 1}`}
              >
                <Page
                  ref={pagesRefs[index]}
                  key={`page_${index + 1}`}
                  pageNumber={index + 1}
                  renderTextLayer={false}
                  renderAnnotationLayer={false}
                  onDrop={handleCanvasDrop}
                  onDragOver={(e) => e.preventDefault()}
                  onClick={() => handlePageClick(index + 1)}
                >
                  <>
                    {images
                      .filter((image) => image.page === index + 1)
                      .map((image, idx) => (
                        <div key={idx} className="image-container">
                          <img
                            src={image.imageUrl}
                            alt="Draggable Image"
                            draggable
                            onDragStart={(e) => handleImageDragStart(e, image)}
                            style={{
                              position: "absolute",
                              left: `${image.position.x}px`,
                              top: `${image.position.y}px`,
                              height: "70px",
                            }}
                          />
                        </div>
                      ))}
                  </>
                </Page>
              </div>
            ))}
          </Document>
          <div>
            <button onClick={handleDownloadPDF}>Download PDF</button>
          </div>
        </div>
      </center>
    </div>
  );
};

export default PDFEditor;
