import React, { useState } from "react";
import ConfirmationAlert from "../../../Components/ConfirmationAlert";
import SuccessAlert from "../../../Components/SuccesAlert";
import { useNavigate } from "react-router-dom";

function RevisiProposal() {

    const [formData, setFormData] = useState({
        selectedFile: null,
    });

    const { selectedFile } = formData;

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        setFormData({ ...formData, selectedFile: file });
    };

    const handleDrop = (e) => {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        setFormData({ ...formData, selectedFile: file });
    };

    const handleFileDelete = () => {
        setFormData({ ...formData, selectedFile: null });
        document.querySelector('#fileProposal').value = '';
    };

    const navigate = useNavigate();

    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const handleConfirm = () => {
        setShowConfirmationAlert(true);
    };

    const confirmData = () => {
        setShowConfirmationAlert(false);
        setShowSuccessAlert(true);
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
        navigate('/mahasiswa/pengajuan/proposal');
        window.location.reload();
    };

    return (
      <div className="p-3">
        <div className="container-fluid">
          <div
            className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow"
            style={{ backgroundColor: "#f0f0f0" }}
          >
            <a
              href="/mahasiswa/pengajuan/proposal"
              className="text-decoration-none text-black"
            >
              Pengajuan Proposal Kegiatan
            </a>
            <i className="bi bi-chevron-right mx-2"></i>
            <span className="text-decoration-none text-black">
              Edit Proposal
            </span>
          </div>
          <div className="my-3 fs-2 fw-bold text-black">
            Edit Proposal Kegiatan
          </div>
          <div className="mx-5">
            <div className="d-flex flex-column justify-content-center mt-4 mx-5">
              <div className="border border-1 border-black rounded-4">
                <div className="d-flex align-items-center my-2 ms-4 fs-5 fw-bold">
                  Masukkan Berkas yang Sudah Direvisi
                </div>
                <div className="d-flex justify-content-center mb-4 mx-4">
                  <div
                    className="form-group file-input-container rounded-3"
                    onDragOver={(e) => e.preventDefault()}
                    onDrop={handleDrop}
                    style={{ width: "850px", height: "" }}
                  >
                    <div className="drop-zone d-flex flex-column">
                      {selectedFile ? (
                        <div className="selected-file d-flex my-4 mx-auto justify-content-center py-1 bg-gray-2 rounded-5 overflow-hidden">
                          <span className="ms-2">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="30"
                              height="30"
                              viewBox="0 0 40 40"
                            >
                              <g fill="#f40f02">
                                <path d="M14.105 20.35c0-.76-.528-1.214-1.459-1.214c-.38 0-.637.037-.772.073v2.441c.16.036.356.049.625.049c.993 0 1.606-.503 1.606-1.349zm5.766-1.19c-.417 0-.687.037-.847.074v5.408c.16.037.417.037.65.037c1.692.012 2.796-.92 2.796-2.894c.013-1.717-.993-2.625-2.599-2.625z" />
                                <path d="M30.918 15.983h-.678v-3.271a.448.448 0 0 0-.006-.062a.545.545 0 0 0-.131-.359L24.66 6.075l-.004-.004a.566.566 0 0 0-.255-.159l-.03-.01a.539.539 0 0 0-.127-.016H10.867c-.611 0-1.107.497-1.107 1.107v8.99h-.678c-.874 0-1.582.708-1.582 1.582v8.228c0 .873.709 1.582 1.582 1.582h.678v5.633c0 .61.496 1.107 1.107 1.107h18.266c.61 0 1.107-.497 1.107-1.107v-5.633h.678c.874 0 1.582-.709 1.582-1.582v-8.228c0-.874-.709-1.582-1.582-1.582zm-20.051-8.99H23.69v5.664c0 .306.248.553.554.553h4.89v2.773H10.867v-8.99zm13.59 14.73c0 1.582-.576 2.674-1.375 3.348c-.87.723-2.194 1.067-3.813 1.067c-.969 0-1.655-.062-2.122-.122v-8.119c.687-.11 1.582-.171 2.527-.171c1.569 0 2.587.282 3.384.883c.859.637 1.399 1.655 1.399 3.114zm-14.436 4.33v-8.156c.576-.098 1.386-.171 2.526-.171c1.153 0 1.975.221 2.526.662c.527.417.882 1.104.882 1.913c0 .81-.269 1.496-.76 1.962c-.638.601-1.582.871-2.686.871c-.245 0-.466-.012-.637-.037v2.956h-1.851zm19.112 6.655H10.867v-5.333h18.266v5.333zm1.604-13.388H27.56v1.889h2.968v1.521H27.56v3.323h-1.875v-8.266h5.052v1.533z" />
                              </g>
                            </svg>
                          </span>
                          <span>{selectedFile.name}</span>
                          <button
                            type="button"
                            className="py-1 border-0 ms-3 rounded-5 bg-gray-1"
                            onClick={handleFileDelete}
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="20"
                              height="20"
                              viewBox="0 0 40 40"
                            >
                              <path
                                fill="white"
                                d="M21.499 19.994L32.755 8.727a1.064 1.064 0 0 0-.001-1.502c-.398-.396-1.099-.398-1.501.002L20 18.494L8.743 7.224c-.4-.395-1.101-.393-1.499.002a1.05 1.05 0 0 0-.309.751c0 .284.11.55.309.747L18.5 19.993L7.245 31.263a1.064 1.064 0 0 0 .003 1.503c.193.191.466.301.748.301h.006c.283-.001.556-.112.745-.305L20 21.495l11.257 11.27c.199.198.465.308.747.308a1.058 1.058 0 0 0 1.061-1.061c0-.283-.11-.55-.31-.747L21.499 19.994z"
                              />
                            </svg>
                          </button>
                        </div>
                      ) : (
                        <>
                          <div
                            className="content d-flex align-items-center"
                            style={{ height: "200px" }}
                          >
                            <div className="fs-5">
                              <p>Seret dan Jatuhkan Berkas di Sini</p>
                              <p>Atau</p>
                            </div>
                            <button
                              type="button"
                              className="bg-blue-1 text-white rounded-3 px-5 py-1 border-0"
                              onClick={() =>
                                document.querySelector("#fileProposal").click()
                              }
                            >
                              Browse File
                            </button>
                          </div>
                          <div className="mt-2 fs-6 fst-italic">
                            *Pastikan Berkas dalam bentuk PDF
                          </div>
                        </>
                      )}
                    </div>
                    <input
                      type="file"
                      id="fileProposal"
                      accept=".pdf"
                      onChange={handleFileChange}
                      style={{ display: "none" }}
                      key={selectedFile ? selectedFile.name : "no-file"}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center grid gap-3 my-5">
                <button className="bg-blue-1 text-white border-0 rounded-3 py-2 col-2">
                  Batalkan
                </button>
                <button
                  type="button"
                  className="bg-blue-1 text-white border-0 rounded-3 py-2 col-2"
                  onClick={handleConfirm}
                >
                  Konfirmasi
                </button>
              </div>
              {showConfirmationAlert && (
                <ConfirmationAlert
                  showAlert={showConfirmationAlert}
                  confirmationMessage="Anda Yakin untuk Mengunggah Dokumen ke Sistem ?"
                  buttonLabel1="Submit Document"
                  buttonLabel2="Back to Preview Page"
                  onConfirm={confirmData}
                  onClose={closeConfirmationAlert}
                />
              )}
              {showSuccessAlert && (
                <SuccessAlert
                  showAlert={showSuccessAlert}
                  successMessage="Submit Proposal Berhasil!"
                  buttonLabel="Back to Proposal Page"
                  onClose={closeSuccessAlert}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    );
}

export default RevisiProposal;