import React from 'react';
import TinjauDokumen from '../../../Components/EditDokumen';

function TinjauLpjDosen() {
    return (
        <div className="p-3">
            <div className="container-fluid">
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <div className='text-decoration-none text-black'>Tinjau Dokumen LPJ Kegiatan Mahasiswa</div>
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Tinjau Dokumen LPJ Kegiatan Mahasiswa
                </div>
                <TinjauDokumen role="dosen" dokumen="LPJ" />
            </div>
        </div>
    );
}

export default TinjauLpjDosen;