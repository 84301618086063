import React from "react";
import { useNavigate } from "react-router-dom";
import PDFViewer from "./PDFViewer";

const DetailDokumen = ({ dokumen, role }) => {
  const navigate = useNavigate();

  const handleClose = () => {
    navigate(-1);
  };

  const viewDocument = (id) => {
    if (dokumen && dokumen.type) {
      let navigateUrl = "";
      if (role === "mahasiswa") {
        navigateUrl = `/mahasiswa/pengajuan/${dokumen.type.toLowerCase()}/tinjau/${id}`;
      } else if (role === "dosen") {
        navigateUrl = `/dosen/${dokumen.type.toLowerCase()}/tinjau/${id}`;
      } else if (role === "kadep") {
        navigateUrl = `/kadep/${dokumen.type.toLowerCase()}/tinjau/${id}`;
      } else if (role === "admin") {
        navigateUrl = `/admin/${dokumen.type.toLowerCase()}/tinjau/${id}`;
      } else if (role === "pengunjung") {
        navigateUrl = `/${dokumen.type.toLowerCase()}/tinjau/${id}`;
      }

      navigate(navigateUrl);
    } else {
      console.error("Type is undefined or the document doesn't exist.");
    }
  };

  if (!dokumen) {
    return <div>Loading...</div>;
  }

  const { title, status, organization, date, type } = dokumen;

  return (
    <div>
      <div className="row g-1 g-md-2 my-3">
        <div className="col-12 col-xl-6">
          <div className="mb-3 fs-2 fw-bold text-black">
            Detail Kegiatan Mahasiswa
          </div>
          <div className="px-2 px-md-3">
            <div className="mb-4">
              <div className="fs-4 fw-bold mb-3">Penanggungjawab</div>
              <div className="row mx-1 mx-lg-3">
                <div className="col-6 col-md-4">Nama</div>
                <div className="col-6 col-md-8">Nancy Wheller</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">NIM</div>
                <div className="col-6 col-md-8">123456</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">No. Handphone</div>
                <div className="col-6 col-md-8">08123456789</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">Jabatan</div>
                <div className="col-6 col-md-8">Sekretaris Himaskom</div>
              </div>
            </div>
            <div className="mb-1">
              <div className="fs-4 fw-bold mb-3">Detail Kegiatan</div>
              <div className="row mx-1 mx-lg-3">
                <div className="col-6 col-md-4">Nama Kegiatan</div>
                <div className="col-6 col-md-8">{title}</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">Tanggal Mulai</div>
                <div className="col-6 col-md-8">{date}</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">Tanggal Selesai</div>
                <div className="col-6 col-md-8">{date}</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">Tempat</div>
                <div className="col-6 col-md-8">Place</div>
                <div className="w-100 py-1"></div>
                <div className="col-6 col-md-4">Tujuan Kegiatan</div>
                <div className="col-6 col-md-8">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. In
                  sed lorem magna.
                </div>
              </div>
              {status === "Disetujui" && (
                <div className="mt-5 d-flex flex-column mx-3 col-6">
                  <div className="d-flex flex-row align-items-center">
                    <div className="col">
                      <img src="/img/qr.png" alt="" height={110} width={110} />
                    </div>
                    <div className="d-flex flex-column gap-1 text-gray-4">
                      <span className="badge status-disetujui">{status}</span>
                      <span>001/HIMA/PPK/2023</span>
                      <span>{organization}</span>
                    </div>
                  </div>
                  <button className="btn-blue-1 px-3 mx-3 my-2">
                    Download Document
                  </button>
                </div>
              )}
              {(status === "Diproses" ||
                status === "Revisi" ||
                status === "Ditolak") && (
                <div className="mt-5 d-flex flex-column align-items-center">
                  <img src="/img/alert.png" alt="" height={64} width={64} />
                  <div>
                    Dokumen tidak bisa diunduh karena belum mendapatkan
                    persetujuan
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-12 col-xl-6 mt-5 mt-xl-0">
          <center>
            <div
              className="mx-3 my-4 mx-xl-4 border border-black rounded-4 overflow-hidden"
              style={{ height: "600px", width: "450px" }}
            >
              <div
                className="border-bottom border-2"
                style={{ backgroundColor: "#f0f0f0", height: "45px" }}
              ></div>
              <div className="mx-auto" style={{ height: "510px" }}></div>
              <div
                className="border-top border-2 text-center"
                style={{ backgroundColor: "#f0f0f0", height: "45px" }}
              >
                <span>Halaman </span>
                <span>1 </span>
                <span>dari </span>
                <span>10</span>
              </div>
            </div>
          </center>
        </div>
      </div>
      <div className="mb-5 mx-3 gap-3 d-flex justify-content-end">
        <button className="btn-blue-1" onClick={handleClose}>
          Close
        </button>
        <button className="btn-blue-1" onClick={() => viewDocument(dokumen.id)}>
          View Document
        </button>
      </div>
    </div>
  );
};

export default DetailDokumen;
