import React from 'react';

function LpjAdmin() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Daftar Pengajuan Laporan Pertanggungjawaban Kegiatan Mahasiswa
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Kelola Laporan Pertanggungjawaban Kegiatan Mahasiswa
                </div>
                <div className='my-5 my-md-0 row g-2 d-flex'>
                    <div className='col-12 col-md-6 d-flex flex-column gap-3 align-items-center justify-content-center'>
                        <a href='/admin/lpj/update' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Update Dokumen LPJ</a>
                        <a href='/admin/lpj/arsip' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Arsip Dokumen LPJ</a>
                        <a href='/admin/lpj/delete' className='btn-blue-1 text-center text-decoration-none' style={{width: '300px'}}>Hapus Dokumen LPJ</a>
                    </div>
                    <div className='col-12 col-md-6 d-none d-md-block'>
                        <img src="/img/Pengajuan.png" alt="Pengajuan" style={{width: window.innerWidth >= 992 ? 500 : 350}}/>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default LpjAdmin;