import React, { useState } from 'react';
import PengajuanLpj from './PengajuanLpj';
import DetailPengajuanLpj from './DetailPengajuanLpj';
import PreviewLpj from './PreviewLpj';
import SuccessAlert from '../../../Components/SuccesAlert';
import { useNavigate } from 'react-router-dom';

const Lpj = () => {

  const [step, setStep] = useState(1);
  const [formValues, setFormValues] = useState({
    nomorKegiatan: '',
    bidangOrmawa: '',
    alurKegiatan: '',
    ruangLingkupKegiatan: '',
    namaPj: '',
    nimPj: '',
    nomorPj: '',
    positionPj: '',
    namaKegiatan: '',
    tglMulai: '',
    tglSelesai: '',
    tempat: '',
    fileLpj: ''
  });
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const navigate = useNavigate();

  const nextStep = () => {
    setStep(step + 1);
  }

  const prevStep = () => {
    setStep(step - 1);
  }

  const handleChange = (input) => (e) => {
    setFormValues({ ...formValues, [input]: e.target.value });
    setShowSuccessAlert(true);
  }

  const successAlertClose = () => {
    setShowSuccessAlert(false);
    navigate('/mahasiswa/pengajuan/lpj');
    window.location.reload();
  }

  switch (step) {
    case 1:
      return (
        <PengajuanLpj
          nextStep={nextStep}
          handleChange={handleChange}
          values={formValues}
        />
      );
    case 2:
      return (
        <DetailPengajuanLpj
          nextStep={nextStep}
          prevStep={prevStep}
          handleChange={handleChange}
          values={formValues}
        />
      );
    case 3:
      return (
        <PreviewLpj
          nextStep={nextStep}
          prevStep={prevStep}
          values={formValues}
        />
      );
    default:
      return (
        <div>
          {showSuccessAlert && (
            <SuccessAlert
              successMessage='Submit LPJ Berhasil!'
              buttonLabel='Back to LPJ Page'
              onClose={successAlertClose} />
          )}
        </div>
      )

  }
}

export default Lpj;
