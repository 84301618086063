import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import dataUser from '../user.json';
import ConfirmationAlert from './ConfirmationAlert';
import SuccessAlert from './SuccesAlert';

const TabelUser = ({ pageData }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dataOrder, setDataOrder] = useState('Terbaru');
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);
    const [showSuccessAlert, setShowSuccessAlert] = useState(false);

    const [filteredUser, setFilteredUser] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    useEffect(() => {
        const dataFilter = (data) => {
            if (dataOrder === 'Terbaru') {
                return data.sort((a, b) => new Date(b.date) - new Date(a.date));
            } else if (dataOrder === 'Admin') {
                return data.filter(item => item.position === 'Admin');
            } else if (dataOrder === 'Kepala Departemen') {
                return data.filter(item => item.position === 'Kepala Departemen');
            } else if (dataOrder === 'Dosen Kemahasiswaan') {
                return data.filter(item => item.position === 'Dosen Kemahasiswaan');
            } else if (dataOrder === 'Mahasiswa') {
                return data.filter(item => item.position === 'Mahasiswa');
            } else {
                return data;
            };
        };

        const filteredData = dataUser.filter((item) => {
            const name = `${item.firstName} ${item.lastName}`;
            return name.toLowerCase().includes(searchTerm.toLocaleLowerCase());
        });

        const dataResult = dataFilter(filteredData);

        setFilteredUser(dataResult);

    }, [searchTerm, dataOrder]);

    const navigate = useNavigate();
    
    const handleConfirm = () => {
        setShowConfirmationAlert(true);
    };

    const confirmData = () => {
        setShowConfirmationAlert(false);
        console.log("data berhasil dihapus");
        setShowSuccessAlert(true);
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    const closeSuccessAlert = () => {
        setShowSuccessAlert(false);
        navigate('/admin/account/delete');
        window.location.reload();
    }

    const handleEdit = () => {
        navigate('/admin/account/update/id');
    };

    const handleSearchChange = (e) => {
        setSearchTerm(e.target.value);
    };

    const handleDataOrderChange = (e) => {
        setDataOrder(e.target.value);
    };

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentItems = filteredUser.slice(indexOfFirstItem, indexOfLastItem);

    const paginate = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    return (
        <div className='mx-0 m-md-3'>
            <div className="d-flex flex-column flex-md-row justify-content-between mb-4 gap-3">
                <div className="col-12 col-md-3">
                    <input
                        type="text"
                        placeholder="Cari User..."
                        value={searchTerm}
                        onChange={handleSearchChange}
                        className="form-control me-2"
                    />
                </div>
                <div className='col-12 col-md-3 d-flex align-items-center'>
                    <span className='fw-semibold me-3'>Filter</span>
                    <select
                        value={dataOrder}
                        onChange={handleDataOrderChange}
                        className="form-select"
                    >
                        <option value="Terbaru">Terbaru</option>
                        <option value="Admin">Admin</option>
                        <option value="Kepala Departemen">Kepala Departemen</option>
                        <option value="Dosen Kemahasiswaan">Dosen Kemahasiswaan</option>
                        <option value="Mahasiswa">Mahasiswa</option>
                    </select>
                </div>
            </div>

            <div className='dokumen table-responsive'>
                <table className="table table-striped">
                    <thead>
                        <tr>
                            <th className='px-3'>No.</th>
                            <th>Name</th>
                            <th>Organization</th>
                            <th>Number Phone</th>
                            <th>Role</th>
                            <th>Email</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentItems.map((item, index) => {
                            const name = `${item.firstName} ${item.lastName}`;
                            return (
                                <tr key={index}>
                                    <td className='px-4'>{index + 1 + indexOfFirstItem}</td>
                                    <td>{name}</td>
                                    <td>{item.organization}</td>
                                    <td>{item.phoneNumber}</td>
                                    <td>{item.position}</td>
                                    <td>{item.email}</td>
                                    <td>
                                        {pageData === 'update' && (
                                            <button onClick={handleEdit} className="btn rounded-circle">
                                                <img src="/img/edit.svg" alt="Edit" />
                                            </button>
                                        )}
                                        {pageData === 'delete' && (
                                            <button onClick={handleConfirm} className="btn rounded-circle">
                                                <img src="/img/delete.svg" alt="Delete" />
                                            </button>
                                        )}
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>

            <div className='my-5'>
                <nav>
                    <ul className="pagination d-flex justify-content-between">
                        <li className={`page-item ${currentPage === 1 ? 'disabled' : ''}`}>
                            <button className="page-link bg-blue-1 text-white rounded-3" style={{ width: '100px' }} onClick={() => paginate(currentPage - 1)}>Previous</button>
                        </li>
                        <div>
                            Halaman {currentPage} dari {Math.ceil(filteredUser.length / itemsPerPage)}
                        </div>
                        <li className={`page-item ${currentPage === Math.ceil(filteredUser.length / itemsPerPage) ? 'disabled' : ''}`}>
                            <button className="page-link bg-blue-1 text-white rounded-3" style={{ width: '100px' }} onClick={() => paginate(currentPage + 1)}>Next</button>
                        </li>
                    </ul>
                </nav>
            </div>
            {showConfirmationAlert && (
                <ConfirmationAlert
                    showAlert={showConfirmationAlert}
                    confirmationMessage="Anda Yakin untuk Menghapus Akun?"
                    buttonLabel1="Delete Account"
                    buttonLabel2="Back to Previous Page"
                    onConfirm={confirmData}
                    onClose={closeConfirmationAlert}
                />
            )}
            {showSuccessAlert && (
                <SuccessAlert
                    showAlert={showSuccessAlert}
                    successMessage="Akun Berhasil Dihapus!"
                    buttonLabel="Back to List"
                    onClose={closeSuccessAlert}
                />
            )}
        </div>
    );
};

export default TabelUser;
