import React from 'react';
import TabelUser from '../../../Components/TabelUser';

function DeleteAccount() {
    return (
        <div className='p-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    Hapus Akun Pengguna
                </div>
                <div className='my-3 fs-2 fw-bold text-black'>
                    Daftar Pengguna Aplikasi
                </div>
                <TabelUser pageData="delete"/>
            </div>
        </div>
    );
}

export default DeleteAccount;