import React, { useState } from 'react';
import ConfirmationAlert from '../../../Components/ConfirmationAlert';

function PreviewProposal({ nextStep, prevStep, values }) {
    
    const [showConfirmationAlert, setShowConfirmationAlert] = useState(false);

    const handleNext = (e) => {
        e.preventDefault();
        nextStep();
    }

    const handleBack = (e) => {
        e.preventDefault();
        prevStep();
    }

    const {
        namaPj,
        nimPj,
        nomorPj,
        positionPj,
        namaKegiatan,
        tglMulai,
        tglSelesai,
        tempat,
    } = values;

    const handleConfirm = () => {  
        setShowConfirmationAlert(true);
    };

    const closeConfirmationAlert = () => {
        setShowConfirmationAlert(false);
    };

    return (
        <div className='p-2 p-md-3'>
            <div className='container-fluid'>
                <div className="mb-4 p-3 rounded rounded-4 fs-6 fw-bold shadow" style={{ backgroundColor: '#f0f0f0' }}>
                    <a href="/mahasiswa/pengajuan/proposal" className='text-decoration-none text-black'>Pengajuan Proposal Kegiatan</a>
                    <i className='bi bi-chevron-right mx-2'></i>
                    <a href="/mahasiswa/pengajuan/proposal/detail" className='text-decoration-none text-black'>Detail Pengajuan Proposal</a>
                    <i className='bi bi-chevron-right mx-2'></i>
                    <a href="/mahasiswa/pengajuan/proposal/detail/upload" className='text-decoration-none text-black'>Unggah Proposal Baru</a>
                    <i className='bi bi-chevron-right mx-2'></i>
                    <a href="/mahasiswa/pengajuan/proposal/detail/preview" className='text-decoration-none text-black'>Preview Proposal Kegiatan</a>
                </div>
                <div className='row g-1 g-md-2 my-3'>
                    <div className='col-12 col-md-6'>
                        <div className='mb-3 fs-2 fw-bold text-black'>
                            Preview Proposal Kegiatan
                        </div>
                        <div className='px-2 px-md-3'>
                            <div className='mb-4'>
                                <div className='fs-4 fw-bold mb-3'>Penanggungjawab</div>
                                <div className='row mx-1 mx-lg-3 g-1'>
                                    <div className='col-6 col-md-4'>Nama</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{namaPj}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>NIM</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{nimPj}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>No. Handphone</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{nomorPj}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>Jabatan</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{positionPj}</div>
                                </div>
                            </div>
                            <div className='mb-4'>
                                <div className='fs-4 fw-bold mb-3'>Detail Kegiatan</div>
                                <div className='row mx-1 mx-lg-3 g-1'>
                                    <div className='col-6 col-md-4'>Nama Kegiatan</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{namaKegiatan}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>Tanggal Mulai</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{tglMulai}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>Tanggal Selesai</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{tglSelesai}</div>
                                    <div className='w-100 py-1'></div>
                                    <div className='col-6 col-md-4'>Tempat</div>
                                    <div className='col-6 col-md-8' style={{ overflowWrap: 'break-word' }}>{tempat}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='col-12 col-md-6 mt-5 mt-md-0'>
                        <div className='mx-auto my-4 mx-md-3 border border-black rounded-4 overflow-hidden' style={{ height: window.innerWidth>=768 ? '600px': `${(window.innerWidth / 450) * 600}px`, width: window.innerWidth>= 768 ? '450px' : '100%' }}>
                            <div className='border-bottom border-2' style={{ backgroundColor: '#f0f0f0', height: '45px' }}></div>
                            <div className='mx-auto' style={{ height: window.innerWidth >= 768 ? '510px' : `${((window.innerWidth / 450) * 600) - 90}px` }}>
                                
                            </div>
                            <div className='border-top border-2 text-center' style={{ backgroundColor: '#f0f0f0', height: '45px' }}>
                                <span>Halaman </span>
                                <span>1 </span>
                                <span>dari </span>
                                <span>10</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='my-5 d-flex justify-content-center'>
                    <button className='m-2 btn-blue-1' onClick={handleBack}>
                        Previous
                    </button>
                    <button className='m-2 btn-blue-1' onClick={handleConfirm}>
                        Submit
                    </button>
                </div>
            </div>
            {showConfirmationAlert && (
                <ConfirmationAlert
                showAlert={showConfirmationAlert}
                onConfirm={handleNext}
                onClose={closeConfirmationAlert}
            />
            )}
        </div>
    );
}

export default PreviewProposal;